import { cloneDeepWith, isFunction, isNil } from 'lodash-es';
import { getSocket } from './socket';
import { Logger, VError } from '@tellsla/common';
import { config } from '@tellsla/config';

export * from './socket';

const logger = Logger('wsApi');

export class NotConnectedError extends VError {
    constructor(message?: string, ...params: unknown[]) {
        super(message ?? 'not connected', params);
        this.name = 'NotConnectedError';
    }
}
/**
 * Отправляет на сервер команду с данными, ждет заданное время ответа.
 * @param tag Идентификатор отправляемой команды
 * @param data Данные для команды
 * @param timeout время, за которое запрос должен случиться.
 * @returns
 */
export function requestServer(tag: string, data: unknown = {}, timeout?: number) {
    const socket = getSocket();

    if (!isNil(socket)) {
        const dataToSend = cloneDeepWith(data);
        return socket.request(tag, dataToSend, timeout);
    }
    return Promise.reject(new NotConnectedError('not connected'));
}

export type WSApiCallbackFunction = (response: unknown) => void;

/**
 * Обертка для функции работы с сервером, которая вместо бросания исключения возвращает ошибку в виде результата
 * @param tag Идентификатор отправляемой команды
 * @param data Данные для команды
 * @param callback Функция, обработчик ответа от сервера
 * @param timeout время, за которое запрос должен случиться.
 */
export function sendToServer(tag: string, data: unknown, callback?: WSApiCallbackFunction, timeout?: number) {
    let reTimeout;
    if (!isNil(callback) && !isNil(timeout)) {
        reTimeout = timeout ? timeout : config.backendHost.timeout;
    }
    requestServer(tag, data, reTimeout)
        .then((response: unknown) => (isFunction(callback) ? callback(response) : null))
        .catch((error: Error) => (isFunction(callback) ? callback(error) : logger.error(error)));
}
