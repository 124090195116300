import React from 'react';
import { Backdrop, CircularProgress } from '@mui/material';
import { useSelector } from 'react-redux';

type TColorUser = 'success' | 'secondary' | 'inherit' | 'primary' | 'error' | 'info' | 'warning' | undefined;

export interface ICircularWaiting {
    colorUser?: TColorUser;
    UserSize?: number;
    inProgress: boolean;
}

/**
 * Компонет для отрисовки вращающегося символа ожидания в виде кольца
 * @param colorUser
 * @param UserSize
 * @returns
 */
export function CircularWaiting({ colorUser = 'success', UserSize = 64, inProgress }: ICircularWaiting) {
    return (
        <>
            {inProgress && (
                <Backdrop
                    color="secondary"
                    open={inProgress}
                    sx={{ zIndex: 1350 }}
                    transitionDuration={{ enter: 700, appear: 300, exit: 0 }}>
                    <CircularProgress size={UserSize} color={colorUser} />
                </Backdrop>
            )}
        </>
    );
}

export interface IIsWaitingContext {
    isWaiting: boolean;
    message?: string | null;
    setIsWaiting: (isWaiting: boolean, message?: string | null) => void;
}
export const IsWaitingContext = React.createContext<IIsWaitingContext>({
    isWaiting: false,
    message: null,
    setIsWaiting: (isWaitingFor: boolean, message?: string | null) => {},
});

export const IsWaitingProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
    const [contextValue, setIsWaitingContextValue] = React.useState<{
        isWaiting: boolean;
        message?: string | null;
    }>({
        isWaiting: false,
        message: null,
    });

    return (
        <IsWaitingContext.Provider
            value={{
                ...contextValue,
                setIsWaiting: (isWaiting, message = null) => setIsWaitingContextValue({ isWaiting, message }),
            }}>
            {children}
            {contextValue && <CircularWaiting inProgress={contextValue.isWaiting} />}
        </IsWaitingContext.Provider>
    );
};

export const useIsWaiting = () => React.useContext(IsWaitingContext);
