import {
    Box,
    Stack
} from '@mui/material';
import React, { useEffect } from 'react';
import { TimerEffectsListEdit } from './TimerEffectsListEdit';

import { TimerTimeValueInputTable } from './TimerTimeValueInputTable';

export interface ITimerEditorContext {
    fieldWithPath: (fieldName: string) => string;
    fieldNamePrefix: string | null;
    methods: any;
}

export const TimerEditorContext = React.createContext<ITimerEditorContext>({
    fieldWithPath: (fieldName: string) => fieldName,
    fieldNamePrefix: null,
    methods: {}
});

export const TimerEditComponent = ({ fieldName, methods }) => {
    const { watch, setValue } = methods;

    function fieldWithPath(field: string) {
        return (fieldName ? `${fieldName}.` : '') + field;
    }

    const maxValue = watch(fieldWithPath('params.maxValue'));
    const direction = watch(fieldWithPath('params.direction'));

    const [timerEditorContextValue, setTimerEditorContextValue] = React.useState<ITimerEditorContext>({
        fieldWithPath,
        fieldNamePrefix: fieldName,
        methods
    });
    useEffect(() => {
        setTimerEditorContextValue({
            fieldWithPath,
            fieldNamePrefix: fieldName,
            methods
        });
    }, [fieldName]);

    return (
        <TimerEditorContext.Provider value={timerEditorContextValue}>
            <Stack direction="column" spacing={2}>
                <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                    <TimerTimeValueInputTable
                        value={maxValue}
                        onChange={(value) => setValue(fieldWithPath('params.maxValue'), value)}
                        direction={direction}
                        onChangeDirection={(value) => setValue(fieldWithPath('params.direction'), value)}
                    />
                </Box>

                <TimerEffectsListEdit />
            </Stack>
        </TimerEditorContext.Provider>
    );
};
