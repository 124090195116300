import { CheckBox, CheckCircleOutline, DoneAll } from "@mui/icons-material";
import DoubleArrow from "@mui/icons-material/DoubleArrow";
import {
    Box,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
    useTheme,
} from "@mui/material";
import {
    IQResultsFullResults,
    isOrgRole,
    Logger,
    QuizAnswerChoiceMark,
    to100Percent,
} from "@tellsla/common";
import {
    IGroupingSnapshot,
    IQChoiceVariant,
    IQuizQuestion,
    IQuizUserAnswer,
} from "@tellsla/serverTypes";
import { isEmpty, isNil } from "lodash";
import React from "react";
import { useTranslation } from "react-i18next";
import { NoDataPlaceholder } from "../common";
import { QuizReportQuestionChoiceTextAndValue } from "./QuizReportQuestionChoiceTextAndValue";

const logger = Logger("QuizReportQuestionTbl");

interface QuizReportGroupHeadContentProps {
    results: IQResultsFullResults;
    tableId: string;
    percent: number;
    correctHit: number;
    incorrectHit: number;
}
const QuizReportCompactGroupHeadContent: React.FC<QuizReportGroupHeadContentProps> = ({
    results,
    tableId,
    percent,
    correctHit,
    incorrectHit,
}) => {
    const { t } = useTranslation();
    return (
        <Box justifyContent="center">
            <Typography variant="body2" noWrap textOverflow="ellipsis" textAlign="center">
                {`${t("Group")} ${results.quizTotals.tables[tableId].title ?? tableId}`}
            </Typography>
            <Typography
                variant="body2"
                color="gray"
                noWrap
                textOverflow="ellipsis"
                textAlign="center"
            >
                {/* TODO: убрать опциональность userList */}
                {`${results.quizTotals.tables[tableId].userList?.length ?? 0} ` + t("users")}
            </Typography>
            <Typography variant="body2" noWrap textOverflow="ellipsis" textAlign="center">
                {`${percent}% ` + t("correct")}
            </Typography>
            <Typography variant="body2" noWrap textOverflow="ellipsis" textAlign="center">
                {`+${correctHit} - ${incorrectHit} =`}
            </Typography>
            <Typography variant="body2" noWrap textOverflow="ellipsis" textAlign="center">
                {`${correctHit - incorrectHit} ` + t("points")}
            </Typography>
        </Box>
    );
};
// const QuizReportPerUserGroupHeadContent: React.FC<QuizReportGroupHeadContentProps> = ({
//     results,
//     tableId,
//     percent,
//     correctHit,
//     incorrectHit,
// }) => {
//     const { t } = useTranslation();
//     return results.quizTotals.tables[tableId].userList.map((userId) => {
//         return (
//             <TableCell key={`thc-${userId}`}>
//                 <QuizReportUserHeadContent results={results} userId={userId} />
//             </TableCell>
//         );
//     });
// };

interface QuizReportUserHeadContentProps {
    results: IQResultsFullResults;
    userId: string;
    questionId: string;
}
const QuizReportUserHeadContent: React.FC<QuizReportUserHeadContentProps> = ({
    results,
    userId,
    questionId
}) => {
    // logger.debug(`QuizReportUserHeadContent: userId: ${userId}`);
    return (
        <Box justifyContent="center">
            <Typography variant="body2" noWrap textOverflow="ellipsis" textAlign="center">
                {`${results.quizTotals.users?.[userId]?.displayName ?? userId}`}
            </Typography>
            <Typography variant="body2" noWrap textOverflow="ellipsis" textAlign="center">
                {`${to100Percent( results.quizTotals.users?.[userId]?.stats?.[questionId]?.correctPercent)}%`}
            </Typography>
        </Box>
    );
};

interface QuizReportUserAnswerContentProps {
    choiceAnswer: QuizAnswerChoiceMark;
}

const QuizReportUserAnswerContent: React.FC<QuizReportUserAnswerContentProps> = ({
    choiceAnswer,
}) => {
    const theme = useTheme();
    const result =
        choiceAnswer === QuizAnswerChoiceMark.CorrectMark ? (
            <CheckBox color={"success"} />
        ) : choiceAnswer === QuizAnswerChoiceMark.CorrectNoMark ? null : choiceAnswer ===
          QuizAnswerChoiceMark.ErrorMark ? (
            <CheckCircleOutline color={"error"} />
        ) : choiceAnswer === QuizAnswerChoiceMark.ErrorNoMark ? null : null;
    // if (!isNil(choiceAnswer))
    //     console.log('QuizReportUserAnswerContent', {
    //         choiceAnswer,
    //         isCorrect: choiceAnswer === QuizAnswerChoiceMark.CorrectMark,
    //         isError: choiceAnswer === QuizAnswerChoiceMark.Error,
    //         result,
    //     });
    return (
        <Box justifyContent="center">
            <Typography variant="body2" noWrap textOverflow="ellipsis" textAlign="center">
                {result}
            </Typography>
        </Box>
    );
};

interface QuizReportQuestionTblProps {
    questionIndex: number;
    results: IQResultsFullResults;
    question: IQuizQuestion;
    selfAnswer: IQuizUserAnswer;
    selfUserId: string;
    selfUserRole: string;
}
/**
 * Табличный вариант верстки информации о результатах по одному вопросу опроса.
 */
export const QuizReportQuestionTbl: React.FC<QuizReportQuestionTblProps> = ({
    questionIndex,
    results,
    question,
    selfAnswer,
    selfUserId,
    selfUserRole,
}) => {
    const theme = useTheme();
    const { t } = useTranslation();
    const qTotals = results.perQuestionTotals[questionIndex];

    if (isNil(qTotals)) {
        return <NoDataPlaceholder> {t("No results available for the moment.")} </NoDataPlaceholder>;
    }
    const noAnswersNo = results.quizTotals.usersStarted - qTotals.grandTotals.total;

    const tablesToShow: string[] = [];

    const selfTableId = results.quizTotals.users?.[selfUserId]?.location?.tableId;

    const isOrg = isOrgRole(selfUserRole);

    if (selfTableId) {
        tablesToShow.push(selfTableId);
    } else {
        if (isNil(selfUserId) || isOrg) {
            tablesToShow.push(...Object.keys(results.quizTotals.tables));
        }
    }

    return (
        <Box marginBottom={theme.spacing(6)}>
            <Typography variant="h6" gutterBottom>
                {questionIndex + 1}
                {". "}
                {question.questionText}
            </Typography>
            <Box display="flex" flexDirection="row" justifyContent="space-between">
                <TableContainer component={Box}>
                    <Table
                        size="small"
                        sx={{
                            overflowX: "auto",
                            "& .pinned": {
                                position: "sticky",
                                left: 0,
                                zIndex: 2,
                                background: theme.palette.background.paper,
                            },
                        }}
                    >
                        <TableHead>
                            <TableRow key={`trh-${questionIndex}-1`}>
                                <TableCell colSpan={3} className="pinned">
                                    &nbsp;
                                </TableCell>
                                {Object.keys(results.quizTotals.tables).map(
                                    (tableId, tableIndex) => {
                                        const tableCols =
                                            tableId === selfTableId || isNil(selfUserId) || isOrg
                                                ? (results.quizTotals.tables[tableId].userList
                                                      ?.length ?? 0)
                                                : 0;
                                        return tableCols > 0 ? (
                                            <TableCell
                                                key={`thhc-${tableId}-${tableIndex}`}
                                                colSpan={tableCols}
                                                sx={{
                                                    backgroundColor:
                                                        tableIndex % 2 === 0 ? "#f9f9f9" : null,
                                                    textAlign: "center",
                                                }}
                                            >
                                                <Typography
                                                    sx={{ maxWidth: "100%" }}
                                                    noWrap
                                                    textOverflow="ellipsis"
                                                >
                                                    {t("Group")}:{" "}
                                                    {results.quizTotals.tables[tableId].title},{" "}
                                                    {to100Percent(
                                                        results.perTablePoints[tableId]
                                                            .correctPercent
                                                    )}
                                                    %
                                                </Typography>
                                            </TableCell>
                                        ) : null;
                                    }
                                )}
                            </TableRow>
                            <TableRow key={`trh-${questionIndex}-2`}>
                                {/* Указатель правильного ответа */}
                                <TableCell className="pinned">&nbsp;</TableCell>
                                <TableCell className="pinned">&nbsp;</TableCell>
                                <TableCell width={"100%"} className="pinned">
                                    &nbsp;
                                </TableCell>
                                {Object.keys(results.quizTotals.tables).map(
                                    (tableId, tableIndex) => {
                                        const tTotals = results.perTableTotals[tableId];
                                        // const tablePercent = Math.round(
                                        //     ((tTotals?.[questionIndex]?.userAnswers?.correct ?? 0) * 100) /
                                        //         (results.quizTotals.tables[tableId].userList?.length ?? 1) // TODO убрать опциональность userList
                                        // );
                                        return tableId === selfTableId || isNil(selfUserId) || isOrg
                                            ? results.quizTotals.tables[tableId].userList
                                                  .sort((a: string, b: string) =>
                                                      (
                                                          "" +
                                                          (results.quizTotals.users?.[a]
                                                              ?.displayName ?? "")
                                                      ).localeCompare(
                                                          results.quizTotals.users?.[b]?.displayName
                                                      )
                                                  )
                                                  .map((userId, userIndex) => {
                                                      return (
                                                          <TableCell
                                                              key={`thc-${tableId}-${userId}`}
                                                              sx={{
                                                                  backgroundColor:
                                                                      tableIndex % 2 === 0
                                                                          ? "#f9f9f9"
                                                                          : null,
                                                                  maxWidth: "80px",
                                                                  minWidth: "80px",
                                                                  paddingLeft: theme.spacing(0.5),
                                                                  paddingRight: theme.spacing(0.5),
                                                                  textAlign: "center",
                                                                  borderRightColor: "#e9e9e9",
                                                                  borderRightStyle:
                                                                      userIndex <
                                                                      (results.quizTotals.tables[
                                                                          tableId
                                                                      ].userList?.length ?? 0) -
                                                                          1
                                                                          ? "dotted"
                                                                          : "none",
                                                                  borderRightWidth: "thin",
                                                              }}
                                                          >
                                                              <QuizReportUserHeadContent
                                                                  results={results}
                                                                  userId={userId}
                                                                  questionId={question._id}
                                                              />
                                                          </TableCell>
                                                      );
                                                  })
                                            : null;
                                    }
                                )}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {question.choices.map((choice: IQChoiceVariant, choiceIndex) => {
                                const barColor = choice.isCorrect
                                    ? theme.palette.success.main
                                    : "#b0b0b0";
                                const textColor = choice.isCorrect
                                    ? theme.palette.success.main
                                    : theme.palette.text.primary;
                                const percent =
                                    results.quizTotals.usersStarted === 0
                                        ? 0
                                        : ((qTotals.grandTotals.answerStats?.[choice.id] ?? 0) *
                                              100) /
                                          results.quizTotals.usersStarted;
                                const isMyChoice = !isNil(selfAnswer?.answers?.[choice.id]);

                                return (
                                    <TableRow
                                        key={`tr-${choiceIndex}-1`}
                                        id={`tr-${question._id}-${choice.id}-1`}
                                        sx={
                                            {
                                                // '&:last-child td, &:last-child th': { border: 0 },
                                            }
                                        }
                                    >
                                        <TableCell className="pinned">
                                            {isMyChoice &&
                                                (choice.isCorrect ? (
                                                    <DoneAll style={{ color: barColor }} />
                                                ) : (
                                                    <DoubleArrow
                                                        style={{
                                                            color: barColor,
                                                        }}
                                                    />
                                                ))}
                                        </TableCell>
                                        <TableCell className="pinned">{`${choiceIndex + 1}.`}</TableCell>
                                        <TableCell width={"100%"} className="pinned">
                                            <QuizReportQuestionChoiceTextAndValue
                                                barColor={barColor}
                                                textColor={textColor}
                                                choiceText={choice.text}
                                                isMyChoice={false}
                                                percent={percent}
                                            />
                                        </TableCell>
                                        {Object.keys(results.perTableTotals).map(
                                            (tableId, tableIndex) => {
                                                return tableId === selfTableId ||
                                                    isNil(selfUserId) ||
                                                    isOrg
                                                    ? results.quizTotals.tables[tableId].userList
                                                          .sort((a: string, b: string) =>
                                                              (
                                                                  "" +
                                                                  (results.quizTotals.users?.[a]
                                                                      ?.displayName ?? "")
                                                              ).localeCompare(
                                                                  results.quizTotals.users?.[b]
                                                                      ?.displayName
                                                              )
                                                          )
                                                          .map((userId, userIndex) => {
                                                              const userAnswer =
                                                                  results.quizTotals.users[userId]
                                                                      ?.choices?.[
                                                                      String(question._id)
                                                                  ]?.[choice.id];
                                                              return (
                                                                  <TableCell
                                                                      key={`thc-${userId}-${choice.id}`}
                                                                      id={`thc-${userId}-${choice.id}`}
                                                                      sx={{
                                                                          backgroundColor:
                                                                              tableIndex % 2 === 0
                                                                                  ? "#f9f9f9"
                                                                                  : null,
                                                                          borderRightColor:
                                                                              "#e9e9e9",
                                                                          borderRightStyle:
                                                                              userIndex <
                                                                              (results.quizTotals
                                                                                  .tables[tableId]
                                                                                  .userList
                                                                                  ?.length ?? 0) -
                                                                                  1
                                                                                  ? "dotted"
                                                                                  : "none",
                                                                          borderRightWidth: "thin",
                                                                      }}
                                                                  >
                                                                      <QuizReportUserAnswerContent
                                                                          choiceAnswer={userAnswer}
                                                                      />
                                                                  </TableCell>
                                                              );
                                                          })
                                                    : null;
                                            }
                                        )}
                                    </TableRow>
                                );
                            })}
                            <TableRow
                                key={`tr-no-answer`}
                                sx={
                                    {
                                        // '&:last-child td, &:last-child th': {
                                        //     border: 0,
                                        // },
                                    }
                                }
                            >
                                <TableCell className="pinned">
                                    {!isNil(selfAnswer) && isEmpty(selfAnswer?.answers) && (
                                        <DoubleArrow />
                                    )}
                                </TableCell>
                                <TableCell className="pinned">&nbsp;</TableCell>
                                <TableCell className="pinned">
                                    <QuizReportQuestionChoiceTextAndValue
                                        barColor={"#b0b0b0"}
                                        textColor={theme.palette.text.primary}
                                        choiceText={t("No answer")}
                                        isMyChoice={false}
                                        percent={to100Percent(
                                            noAnswersNo / results.quizTotals.usersStarted
                                        )}
                                    />
                                </TableCell>
                                {Object.keys(results.quizTotals.tables).map(
                                    (tableId, tableIndex) => {
                                        const tTotals = results.perTableTotals[tableId];
                                        // const tableNoAnswersNo =
                                        //     (results.quizTotals.tables[tableId].userList?.length ?? 0) - // TODO: убрать опциональность userList
                                        //     (tTotals?.[questionIndex]?.userAnswers?.total ?? 0);
                                        return tableId === selfTableId || isNil(selfUserId) || isOrg
                                            ? results.quizTotals.tables[tableId].userList
                                                  .sort((a: string, b: string) =>
                                                      (
                                                          "" +
                                                          (results.quizTotals.users?.[a]
                                                              ?.displayName ?? "")
                                                      ).localeCompare(
                                                          results.quizTotals.users?.[b]?.displayName
                                                      )
                                                  )
                                                  .map((userId, userIndex) => {
                                                      const userAnswer =
                                                          results.quizTotals.users[userId]
                                                              ?.choices?.[String(question._id)];
                                                      return (
                                                          <TableCell
                                                              key={`tr-no-answer-t-${tableIndex}-${question._id}-${userId}`}
                                                              sx={{
                                                                  textAlign: "center",
                                                                  backgroundColor:
                                                                      tableIndex % 2 === 0
                                                                          ? "#f9f9f9"
                                                                          : null,

                                                                  borderRightColor: "#e9e9e9",
                                                                  borderRightStyle:
                                                                      userIndex <
                                                                      (results.quizTotals.tables[
                                                                          tableId
                                                                      ].userList?.length ?? 0) -
                                                                          1
                                                                          ? "dotted"
                                                                          : "none",
                                                                  borderRightWidth: "thin",
                                                              }}
                                                          >
                                                              {isEmpty(userAnswer) && (
                                                                  <CheckCircleOutline />
                                                              )}
                                                          </TableCell>
                                                      );
                                                  })
                                            : null;
                                    }
                                )}
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
            </Box>
            <Box sx={{ mt: 3 }}>
                <Typography variant="h6">{t("All groups results for this question")}</Typography>
                <TableContainer>
                    <Table sx={{ width: "auto" }}>
                        <TableHead>
                            <TableRow>
                                <TableCell>{t("Group")}</TableCell>
                                {Object.keys(results.quizTotals.tables).map((tableId) => (
                                    <TableCell key={`thg-${tableId}`} sx={{ textAlign: "center" }}>
                                        {results.quizTotals.tables[tableId].title ?? tableId}
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow>
                                <TableCell>{t("Total")}</TableCell>
                                {Object.keys(results.quizTotals.tables).map((tableId) => (
                                    <TableCell key={`tg-${tableId}`}>
                                        <Typography>
                                            {to100Percent(
                                                results.perQuestionTotals[questionIndex]
                                                    ?.perTableTotals?.[tableId]?.correctPercent
                                            ) + "%"}
                                        </Typography>
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
            </Box>
        </Box>
    );
};
