2; /* eslint-disable react/no-array-index-key */
import { Box, Typography } from '@mui/material';
import { IQResultsFullResults, Logger, QuizRunStatus } from '@tellsla/common';
import { IGroupingSnapshot, IQuiz, IQuizRunUsersInfo, IQuizUserAnswer } from '@tellsla/serverTypes';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { NoDataPlaceholder } from '../common';
import { QuizResultsTotalsPerQuestion } from './QuizResultsTotalsPerQuestion';

const logger = Logger('QuizResultsView');

export interface IQuizResultsViewProps {
    quizRunStatus: keyof typeof QuizRunStatus;
    quiz: IQuiz;
    results: IQResultsFullResults;
    selfAnswers: Array<IQuizUserAnswer>;
    selfUserId: string;
    selfUserRole: string;
}

export const QuizResultsView: React.FC<IQuizResultsViewProps> = ({
    quizRunStatus,
    quiz,
    results,
    selfAnswers,
    selfUserId,
    selfUserRole
}) => {
    const { t } = useTranslation();

    if (quizRunStatus !== QuizRunStatus.FINISHED || (results.perQuestionTotals ?? []).length === 0) {
        logger.trace('params error:', { quiz, results, selfAnswers });
        return (
            <Box padding={2}>
                <NoDataPlaceholder>
                    <Typography component="p">{t('Quiz run is not in Finished state.')}</Typography>
                    <Typography component="p">{t('No results available for it.')}</Typography>
                </NoDataPlaceholder>
            </Box>
        );
    }

    logger.trace('QuizResultsView:', { quiz, results, selfAnswers, quizRunStatus });

    return (
        <QuizResultsTotalsPerQuestion
            questions={quiz.questions}
            results={results}
            selfAnswers={selfAnswers}
            selfUserId={selfUserId}
            selfUserRole={selfUserRole}
        />
    );
};
