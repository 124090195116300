import { Box, CircularProgress } from '@mui/material';
import React from 'react';

export const SuspenseFallback: React.FC<React.PropsWithChildren> = ({ children }) => {
    return (
        <Box
            sx={{
                height: '100vh',
                width: '100vw',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            }}>
            <CircularProgress size={64} />
        </Box>
    );
};
