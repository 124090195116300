import { useTheme, Box, Link } from '@mui/material';
import React from 'react';
import PropTypes from 'prop-types';
import { baseApiUrl } from '@tellsla/serverApi';
import { config } from '@tellsla/config';
import logoSvgImg from '../../assets/logo.svg';
import { IBackgroundColors, landingHeaderHeight } from '../Theme/theme';

interface ILandingHeaderProps {
    mainMenu: React.JSX.Element;
}

export const LandingHeader: React.FC<ILandingHeaderProps> = ({ mainMenu }) => {
    const theme = useTheme();
    const bgColors = theme.palette.background as IBackgroundColors;

    return (
        <Box
            display="flex"
            justifyContent="space-between"
            alignItems="baseline"
            sx={{
                height: landingHeaderHeight,
                // mb: theme.spacing(3),
                pb: theme.spacing(1),
                borderBottom: '1px solid black',
            }}
        >
            <Link
                underline="none"
                href={baseApiUrl(config.frontendHost)}
                height="inherit"
                sx={{
                    backgroundColor: bgColors.landing,
                    maxHeight: '100%',
                    mask: { logoSvgImg },
                }}
            >
                <img
                    alt=""
                    src={logoSvgImg}
                    style={{
                        fill: bgColors.landing,
                        maxHeight: '100%',
                        maxWidth: '100%',
                    }}
                />
            </Link>
            {mainMenu}
        </Box>
    );
}
