import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import {
    Box,
    Checkbox,
    FormControl,
    FormControlLabel,
    FormHelperText,
    InputLabel,
    Paper,
    ScopedCssBaseline,
    Stack,
    Typography,
    useTheme,
} from '@mui/material';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Input from '@mui/material/Input';
import InputAdornment from '@mui/material/InputAdornment';
import { Logger } from "@tellsla/common";
import Check from '@mui/icons-material/Check';
import Close from '@mui/icons-material/Close';
import VpnKey from '@mui/icons-material/VpnKey';
import { ICSJResponseErrorItem, VError } from '@tellsla/common';
import { config } from '@tellsla/config';
import { registerUser, requestVerificationCode } from '@tellsla/serverApi';
import { LandingBase, notifyError, useUniversalModalAlert } from '@tellsla/ui-run';
import { isEmpty, isError } from 'lodash-es';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { LicenseLink } from './LicenseLink';

// const logger = Logger("EventRegister");
interface IFieldsState {
    name: string;
    email: string;
    verificationCode: string;
    verificationDay: string;
    password: string;
    password2: string;
    showPassword: boolean;
    licenceCheckBox: boolean;
    errors: any;
}

function Register() {
    const [fields, setFields] = useState<IFieldsState>({
        name: '',
        email: '',
        verificationCode: '',
        verificationDay: '',
        password: '',
        password2: '',
        showPassword: false,
        licenceCheckBox: false,
        errors: {},
    });
    const { openUniversalModal } = useUniversalModalAlert();

    const [buttonDisabled, setButtonDisabled] = useState<{ disabled: boolean; freezeTimeout: any }>({
        disabled: false,
        freezeTimeout: null,
    });
    const theme = useTheme();
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [licenceAgreement, setLicenceAgreement] = React.useState('');

    async function handleDownload() {
        try {
            const file = 'VILTIME_license_agreement.txt';
            fetch(`${file}`)
                .then((response) => response.text())
                .then((text) => {
                    setLicenceAgreement(text);
                });
        } catch (error) {
            console.error('Error downloading file:', error);
        }
    }

    const handleGetCode = async () => {
        const email = (document.getElementById('email') as HTMLInputElement)?.value;
        if (isEmpty(email)) {
            setFields({
                ...fields,
                errors: {
                    email: 'Email field is required',
                },
            });
            return;
        }
        let getCodeFreezeTimeout;
        setButtonDisabled({ ...buttonDisabled, disabled: true });

        try {
            const result = await requestVerificationCode({ email });
            if (isError(result)) {
                const errFieldsResponse = VError.info(result);
                if ((errFieldsResponse?.errors?.length ?? 0) > 0) {
                    const errFields: Record<string, string> = {};
                    errFieldsResponse.errors.forEach((err: ICSJResponseErrorItem) => {
                        errFields[err.domain ?? 'undefined'] = err.message ?? 'Unknown error';
                    });
                    setFields({
                        ...fields,
                        errors: errFields,
                    });
                } else {
                    console.error('Error on get Verification Code:', result);
                    notifyError(result.message);
                }
                setButtonDisabled({ ...buttonDisabled, disabled: false });
                return;
            }
            getCodeFreezeTimeout = setTimeout(() => {
                setButtonDisabled({ disabled: false, freezeTimeout: null });
            }, config.defaults.getRegistrationCodeTimeout);

            setButtonDisabled({ disabled: true, freezeTimeout: getCodeFreezeTimeout });
            const { verificationDay } = result;
            setFields({ ...fields, verificationDay });
            openUniversalModal({
                title: t('Code has been sent'),
                content: `${t('A letter with verification code has been sent to your email box')}. ${t(
                    'If there is no email, please check your spam folder.'
                )}`,
            });
        } catch (error) {
            if (getCodeFreezeTimeout) clearTimeout(getCodeFreezeTimeout);
            setButtonDisabled({ disabled: false, freezeTimeout: null });
            console.error('Catched Error on get Verification Code:', error);
            notifyError(error.response?.data?.error?.message ?? error.message);
        }
    };

    useEffect(() => {
        handleDownload();
        return () => {
            if (buttonDisabled.freezeTimeout) clearTimeout(buttonDisabled.freezeTimeout);
        };
    });

    const setField = (field: any, value: any) => {
        setFields({
            ...fields,
            [field]: value,
            errors: {
                ...fields.errors,
                [field]: null,
            },
        });
    };

    const onChange = (e: any) => {
        if (e.target.id === 'email') {
            const verificationCode = document.getElementById('verificationCode') as HTMLInputElement;
            verificationCode.value = '';
            if (buttonDisabled.freezeTimeout) clearTimeout(buttonDisabled.freezeTimeout);
            if (buttonDisabled.disabled) setButtonDisabled({ ...buttonDisabled, disabled: false });
        }
        setField(e.target.id, e.target.value);
    };

    const onSubmit = (e: any) => {
        e.preventDefault();
        const newUser = {
            name: fields.name,
            email: fields.email,
            verificationCode: fields.verificationCode,
            verificationDay: fields.verificationDay,
            password: fields.password,
            password2: fields.showPassword ? fields.password : fields.password2,
        };
        registerUser(newUser)
            .then((result) => {
                if (isError(result)) {
                    const errFieldsResponse = VError.info(result);

                    if ((errFieldsResponse?.errors?.length ?? 0) > 0) {
                        const errFields: Record<string, string> = {};
                        errFieldsResponse.errors.forEach((err: ICSJResponseErrorItem) => {
                            errFields[`${err.domain}`] = err.message ?? 'Unknown error';
                        });
                        setFields({
                            ...fields,
                            errors: errFields,
                        });
                    }
                    return;
                }
                // TODO: do login
                openUniversalModal({
                    title: t('Registration succedded'),
                    content: `${t('New account was created')}. ${t('You can now login as')} ${
                        result?.user?.profile?.name ?? t('registered user')
                    }`,
                    onClose: () => {
                        const url = new URL(window.location.href);
                        url.pathname = `${config.frontendHost.adminPath}/login`;
                        url.searchParams.set('loginName', `${result?.user?.profile?.email}`);
                        window.location.href = url.toString();
                    },
                });
            })
            .catch((error) => {
                notifyError(error.response?.data?.error?.message ?? error.message);
            });
    };

    const handleClickShowPassword = () => {
        setField('showPassword', !fields.showPassword);
    };

    return (
        <ScopedCssBaseline>
            <LandingBase>
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        height: '100%',
                        width: '100%',
                    }}>
                    <Paper
                        sx={{
                            width: '50%',
                            maxWidth: 'md',
                            display: 'flex',
                            boxSizing: 'border-box',
                            flexDirection: {
                                xs: 'column',
                                sm: 'row',
                            },
                            minHeight: { sm: '480px' },
                        }}>
                        <Box
                            sx={{
                                width: {
                                    xs: '100%',
                                    sm: '100%',
                                },
                            }}>
                            <Box
                                sx={{
                                    width: '100%',
                                    height: '100%',
                                    display: 'flex',
                                    padding: theme.spacing(4),
                                    boxSizing: 'border-box',
                                }}>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        justifyContent: 'space-between',
                                        width: '100%',
                                    }}>
                                    <Typography variant="h4" gutterBottom>
                                        {t('Account registration')}
                                    </Typography>
                                    <form onSubmit={onSubmit}>
                                        <Stack
                                            spacing={3}
                                            direction="column"
                                            justifyContent="center"
                                            alignItems="stretch">
                                            <FormControl
                                                size="medium"
                                                variant="standard"
                                                margin="normal"
                                                fullWidth
                                                error={!isEmpty(fields.errors?.name)}>
                                                <InputLabel htmlFor="name">{t('display name')}</InputLabel>
                                                <Input
                                                    autoComplete="name"
                                                    onChange={onChange}
                                                    id="name"
                                                    type="text"
                                                    required
                                                />
                                                <FormHelperText>{fields.errors?.name}</FormHelperText>
                                            </FormControl>
                                            <FormControl
                                                size="medium"
                                                variant="standard"
                                                margin="normal"
                                                fullWidth
                                                error={!isEmpty(fields.errors?.email)}>
                                                <InputLabel htmlFor="email">{t('email')}</InputLabel>
                                                <Input
                                                    autoComplete="email"
                                                    onChange={onChange}
                                                    required
                                                    id="email"
                                                    type="email"
                                                    error={!isEmpty(fields.errors?.email)}
                                                />
                                                <FormHelperText>{fields.errors?.email}</FormHelperText>
                                            </FormControl>

                                            <Stack direction="row" spacing={2}>
                                                <FormControl
                                                    // size="medium"
                                                    variant="standard"
                                                    margin="normal"
                                                    fullWidth
                                                    error={!isEmpty(fields.errors?.verificationCode)}>
                                                    <InputLabel htmlFor="verificationCode">
                                                        {t('verification code')}
                                                    </InputLabel>
                                                    <Input
                                                        autoComplete="verificationCode"
                                                        onChange={onChange}
                                                        required
                                                        id="verificationCode"
                                                        type="text"
                                                        error={!isEmpty(fields.errors?.verificationCode)}
                                                    />
                                                    <FormHelperText>
                                                        {fields.errors?.verificationCode ??
                                                            (buttonDisabled.disabled &&
                                                                t('Next code request in 1 min'))}
                                                    </FormHelperText>
                                                </FormControl>
                                                <Button
                                                    id="user-register-get-code-button"
                                                    fullWidth
                                                    variant="contained"
                                                    onClick={handleGetCode}
                                                    disabled={buttonDisabled.disabled}
                                                    startIcon={<VpnKey />}
                                                    sx={{
                                                        alignSelf: 'flex-end',
                                                    }}>
                                                    {t('get code')}
                                                </Button>
                                                <Input
                                                    type="hidden"
                                                    id="verificationDay"
                                                    value={fields.verificationDay}
                                                    sx={{ display: 'none' }}
                                                />
                                            </Stack>

                                            <FormControl
                                                size="medium"
                                                variant="standard"
                                                margin="normal"
                                                error={!isEmpty(fields.errors?.password)}>
                                                <InputLabel htmlFor="password">{t('password')}</InputLabel>
                                                <Input
                                                    autoComplete="new-password"
                                                    onChange={onChange}
                                                    required
                                                    id="password"
                                                    type={fields.showPassword ? 'text' : 'password'}
                                                    endAdornment={
                                                        <InputAdornment position="end">
                                                            <IconButton
                                                                aria-label="toggle password visibility"
                                                                onClick={handleClickShowPassword}
                                                                size="large">
                                                                {fields.showPassword ? (
                                                                    <Visibility />
                                                                ) : (
                                                                    <VisibilityOff />
                                                                )}
                                                            </IconButton>
                                                        </InputAdornment>
                                                    }
                                                />
                                                <FormHelperText>{fields.errors?.password}</FormHelperText>
                                            </FormControl>
                                            <FormControl
                                                size="medium"
                                                variant="standard"
                                                margin="normal"
                                                fullWidth
                                                disabled={!!fields.showPassword}
                                                error={
                                                    !isEmpty(fields.errors?.password2) && !fields.showPassword
                                                }>
                                                <InputLabel htmlFor="password2">
                                                    {t('confirm password')}
                                                </InputLabel>
                                                <Input
                                                    autoComplete="new-password"
                                                    onChange={onChange}
                                                    required={!fields.showPassword}
                                                    id="password2"
                                                    type="password"
                                                />
                                                <FormHelperText>
                                                    {fields.showPassword ? '' : fields.errors?.password2}
                                                </FormHelperText>
                                            </FormControl>

                                            <FormControl
                                                size="medium"
                                                variant="standard"
                                                margin="normal"
                                                fullWidth>
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            id="licence-check-box"
                                                            onClick={() =>
                                                                setFields({
                                                                    ...fields,
                                                                    licenceCheckBox: !fields.licenceCheckBox,
                                                                })
                                                            }
                                                            disabled={!licenceAgreement}
                                                        />
                                                    }
                                                    label={
                                                        <Typography>
                                                            {t(
                                                                'I accept the terms of use and confirm that I have read the document'
                                                            ) + ': '}
                                                            <LicenseLink
                                                                text={
                                                                    licenceAgreement ??
                                                                    t(
                                                                        "Sorry, the license agreement hasn't been downloaded yet. Please try again later."
                                                                    )
                                                                }
                                                            />
                                                        </Typography>
                                                    }
                                                />
                                                <FormHelperText>
                                                    {fields.errors?.licenceCheckBox}
                                                </FormHelperText>
                                            </FormControl>

                                            <Stack
                                                direction="row"
                                                spacing={2}
                                                sx={
                                                    {
                                                        // marginTop: theme.spacing(2),
                                                    }
                                                }>
                                                <Button
                                                    fullWidth
                                                    startIcon={<Close />}
                                                    onClick={() => {
                                                        navigate(config.frontendHost.fallbackPath);
                                                    }}>
                                                    {t('Cancel')}
                                                </Button>
                                                <Button
                                                    id="user-register-button"
                                                    variant="contained"
                                                    startIcon={<Check />}
                                                    fullWidth
                                                    type="submit"
                                                    disabled={!(fields.licenceCheckBox && licenceAgreement)}>
                                                    {t('Sign Up')}
                                                </Button>
                                            </Stack>
                                        </Stack>
                                    </form>
                                </Box>
                            </Box>
                        </Box>
                    </Paper>
                </Box>
            </LandingBase>
        </ScopedCssBaseline>
    );
}

export default Register;
