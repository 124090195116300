/* eslint-disable jsx-a11y/media-has-caption */
import React, { useContext, useEffect } from 'react';
import { attachSpeakers } from '../../lib/media/attachSpeakers';
import { EventRunContext } from '../EventRun';
import { VideoPlayerContext } from './VideoPlayerContext';
import { VideoPlayerControllerContext } from './VideoPlayerControllerContext';
import { VideoPlayerEventPublicatorContext } from './VideoPlayerEventPublicatorContext';

try {
    Object.defineProperty(HTMLMediaElement.prototype, 'playing', {
        get() {
            return !!(this.currentTime > 0 && !this.paused && !this.ended && this.readyState > 2);
        },
    });
    // eslint-disable-next-line no-empty
} catch (e) {} // Will catch "property already defined" on hot reload
try {
    Object.defineProperty(HTMLVideoElement.prototype, 'playing', {
        get() {
            return !!(this.currentTime > 0 && !this.paused && !this.ended && this.readyState > 2);
        },
    });
    // eslint-disable-next-line no-empty
} catch (e) {} // Will catch "property already defined" on hot reload

export type VideoPlayerVideoElement = HTMLVideoElement & {
    playing: boolean;
};

export const VideoPlayerVideo: React.FC = () => {
    const { speakerDeviceId, cachedVideoIsMuted } = useContext(VideoPlayerControllerContext);

    const eventRunContext = useContext(EventRunContext);

    const {
        sources,
        videoRef,
        videoElementId,
        videoIsLooping: loop,
        videoIsMuted: muted,
    } = useContext(VideoPlayerContext);

    const mediaRef = videoRef;

    const { publishPlay, publishPause, publishTelemetry } = useContext(VideoPlayerEventPublicatorContext);

    const { speakerVolume } = React.useContext(VideoPlayerControllerContext);

    useEffect(() => {
        if (!mediaRef?.current) return;

        attachSpeakers(mediaRef.current, speakerDeviceId);
    }, [speakerDeviceId]);


    useEffect(() => {
        if (mediaRef && speakerVolume) {
            mediaRef.current.volume = speakerVolume;
            console.log('changed volume to ', speakerVolume, mediaRef.current.volume);
        } else {
            console.log('speakerVolume or mediaElement is null', speakerVolume, mediaRef?.current?.volume);
        }
    }, [speakerVolume]);

    function playPause(event) {
        event.stopPropagation();
        event.preventDefault();
        const mediaElement = mediaRef?.current;
        if (mediaElement && eventRunContext.isOrg) {
            if (mediaElement.playing) {
                mediaElement.pause();
                publishPause(mediaElement.currentTime);
            } else {
                mediaElement.loop = loop;
                mediaElement.play();
                publishPlay(mediaElement.currentTime);
            }
        }
    }

    return (
        <video
            key={videoElementId}
            ref={mediaRef}
            id={videoElementId}
            slot="media"
            preload="auto"
            muted={cachedVideoIsMuted ?? muted}
            crossOrigin=""
            playsInline
            autoPlay={false}
            loop={loop}
            controls={false}
            disablePictureInPicture
            controlsList="nodownload, nofullscreen, noremoteplayback, noplaybackrate"
            style={{ width: '100%', height: '100%' }}
            onClick={playPause}>
            {sources.map((source, index) => (
                <source
                    key={`vid-src-${source.mimetype}-${index}`}
                    id={`${videoElementId}-src-${source.mimetype}-${index}`}
                    src={source.url}
                    type={source.mimetype}
                />
            ))}
            <p>Your browser does not support HTML5 video.</p>
        </video>
    );
};
