import { CssBaseline } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import React, { Suspense } from 'react';

import { ErrorBoundaryLayout, SuspenseFallback, theme, UniversalModalAlertProvider } from '@tellsla/ui-run';

import {
    createBrowserRouter,
    Navigate,
    RouterProvider
} from 'react-router-dom';

import { NotificationListener } from '@tellsla/ui-run';

import LandingHome from './components/landing/LandingHomePage';
import RegisterUser from './components/landing/pages/RegisterUser';
import { TariffInfoBoard } from './components/landing/pages/Tariff/TariffInfoBoard';

function App() {
    console.log('VILTime Landing');

    const router = createBrowserRouter([
        {
            element: <ErrorBoundaryLayout />,
            children: [
                { path: '/', element: <LandingHome /> },
                { path: '/register', element: <RegisterUser /> },
                { path: '/tariffs', element: <TariffInfoBoard /> },
                { path: '*', element: <Navigate replace to="/" /> },
            ],
        },
    ]);

    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <UniversalModalAlertProvider>
                <Suspense fallback={<SuspenseFallback />}>
                    <RouterProvider router={router} />
                    <NotificationListener />
                </Suspense>
            </UniversalModalAlertProvider>
        </ThemeProvider>
    );
}

export default App;
