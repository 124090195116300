import { EventRunTimerStatus, EventRunTimerType, IEventRunTimer } from '@tellsla/serverTypes';
import React from 'react';

export interface ITimerContext {
    timerId: string | null;
    timerType: EventRunTimerType | null;
    timerStatus: EventRunTimerStatus | null;
    timer: IEventRunTimer | null;
}

export const TimerContext = React.createContext<ITimerContext>({
    timerId: null,
    timerType: null,
    timerStatus: null,
    timer: null,
});
