import {
    hostTableNumber,
    IOptions,
    IQChoiceVariant,
    IQuiz,
    IQuizQuestion,
    QuizQuestionTypes,
    IQuizRun,
    IQuizRunUserActivity,
    IQuizUserAnswer,
    IUserLocation,
} from '@tellsla/serverTypes';
import isEmpty from 'lodash-es/isEmpty';
import isNil from 'lodash-es/isNil';
import isNumber from 'lodash-es/isNumber';
import mergeWith from 'lodash-es/mergeWith';
import { Logger } from '../tsLogger';
import { QuizRunUserStatus } from './constants';
import { isTrue } from '../utils';

const logger = Logger('quizUtils');

/**
 * Вычисляем продолжительность между началом и окончанием
 *
 * @param {number} startTime - Время начала
 * @param {number} endTime - Время окончания
 * @return {number} Продолжительность между началом и окончанием
 */
function calcDuration(startTime: number, endTime: number) {
    return isNil(startTime) || isNil(endTime) || endTime == 0 ? 0 : endTime - startTime;
}

export function to100Percent(num: number) {
    return Math.round((num ?? 0) * 100);
}


/**
 * Информация о столе, участвовавшем в опросе
 */
export interface IQResultsTableInfo {
    title: string; // название стола, если было
    userList: string[]; // перечень ID пользователей, участвовавших в опросе за данным столом
}

/**
 * Полный набор столов, участвовавших в опросе
 * { "номер стола": IQResultsTableInfo }
 */
export interface IQResultsTables {
    [tableId: string]: IQResultsTableInfo;
}

export enum QuizAnswerChoiceMark {
    None = 0,
    CorrectMark = 1,
    CorrectNoMark = 2,
    ErrorMark = 3,
    ErrorNoMark = 4,
}

interface IQQuestionAnsweredChoices {
    [choiceId: string]: QuizAnswerChoiceMark;
}

export interface IQAnswersChoiceMarks {
    [questionId: string]: IQQuestionAnsweredChoices;
}

export interface IQResultsUserStats {
    [questionId: string]: {
        correctPercent: number;
    };
}

export interface IQResultUserInfo {
    quizStatus: QuizRunUserStatus;
    location: IUserLocation;
    displayName: string;
    choices: IQAnswersChoiceMarks;
    stats: IQResultsUserStats;
}
export interface IQResultsUsers {
    [userId: string]: IQResultUserInfo;
}

/**
 * Общие результаты проведения опроса
 */
export interface IQResultsQuizTotals {
    startTime: number; // время начала, милисекунды
    finishTime: number; // время окончания, милисекунды
    duration?: number; // продолжительность, мишисекунды
    usersStarted: number; // количество пользователей начавших отвечать
    usersFinished: number; // количество пользователей успешно закончивших отвечать

    usersMidTime: number; // среднее время, затраченное пользователем на опрос
    // TODO: разобраться с метрикой usersMaxMidTime, там, похоже, фигня
    usersMaxMidTime: number; // среди столов/ групп максимальное зарегистрированное время на опрос
    // usersMidTimesSum: number;

    usersMaxErrorsForQuestion: number; // максимальное количество ошибок на какой-либо вопрос

    questionsNo: number; // количество вопросов
    questionsWithCorrectAnswerNo: number; // количество вопросв с прописанным правильным ответом

    questionsFullyAnswered: number; // количество вопросов отвеченных всеми пользователями
    questionsFullyCorrectlyAnswered: number; // количество вопросов корректно отвеченных всеми пользователями

    tables: IQResultsTables; // перечень участвовавших столов
    users: IQResultsUsers;
}

/**
 * Статистика ответов пользователей
 */
export interface IQResultsUserAnswers {
    total: number; // количество выданных ответов
    correct: number; // количество правильных ответов
    errors: number; // количество ошибочных ответов
    correctPercent: number; // процент правильных ответов
    answerStats: { [key: string]: number }; // для каждого ответа общее количество так ответивших
}

/**
 * Статистика ответов для отдельного вопроса
 */
export interface IQResultsQuestionTotals {
    hasCorrectAnswer: boolean; // имеется ли правильный ответ
    variantsNo: number; // количество вариантов ответов
    duration?: {
        midTime: number; // средняя продолжительность раздумий над вопросом
    };
    // usersNo: number; //
    userAnswers?: IQResultsUserAnswers; // статистика ответов пользователей
    correctPercent?: number; // процент правильных ответов
}

/**
 * Статистика ответов на вопросы, сгруппированная по столам.
 */
export interface IQResultsPerTableTotals {
    [tableId: string]: IQResultsQuestionTotals[];
}
export interface IQResultsPerQuestionPerTableTotals {
    [tableId: string]: IQResultsUserAnswers;
}

/**
 * Полная статистика ответов на вопросы по всему проведенному опросу.
 * Добавлены два поля:
 *  - perTableTotals: группировка по столам
 *  - grandTotals: все ответы всех пользователей, посчитанные вместе
 */
export interface IQResultsAllQuestionTotals extends IQResultsQuestionTotals {
    perTableTotals: IQResultsPerQuestionPerTableTotals; // группировка по столам внутри каждого вопроса
    grandTotals: IQResultsUserAnswers; // все ответы всех пользователей, посчитанные вместе
}

/**
 * Статистика количества выбора правильных ответов по столам
 */
export interface IQResultsPerTablePoints {
    [tableId: string]: {
        correct: number;
        incorrect: number;
        total: number;
        correctPercent: number;
        perQuestion: {
            correct: number;
            incorrect: number;
            total: number;
            correctPercent: number;
        }[];
    };
}

/**
 * Общая структура со всей статистикой по проведенному опросу
 */
export interface IQResultsFullResults {
    quizTotals: IQResultsQuizTotals;
    perTableTotals: IQResultsPerTableTotals;
    perQuestionTotals: IQResultsAllQuestionTotals[];
    perTablePoints: IQResultsPerTablePoints;
}

export const emptyQuizRunResults: IQResultsFullResults = {
    quizTotals: {
        startTime: 0,
        finishTime: 0,
        usersStarted: 0,
        usersFinished: 0,
        usersMidTime: 0,
        usersMaxMidTime: 0,
        usersMaxErrorsForQuestion: 0,
        questionsNo: 0,
        questionsWithCorrectAnswerNo: 0,
        questionsFullyAnswered: 0,
        questionsFullyCorrectlyAnswered: 0,
        tables: {},
        users: {},
    },
    perTableTotals: {},
    perQuestionTotals: [],
    perTablePoints: {},
};

// @returns
//   results ~= {
//     quizTotals: {
//         startTime: 0,
//         finishTime: 0,
//         duration: 0,
//         usersStarted: 0,
//         usersFinished: 0,

//         questionsNo: 0,
//         questionsWithCorrectAnswerNo: 0,

//         questionsFullyAnswered: 0,
//         questionsFullyCorrectlyAnswered: 0,
//     },

//     tablesTotals: Map( tableId:            // Map of all tables affected
//         [{                                 // Array of questions as in the Quiz
//             duration: {
//                 midTime: 0,                // Sum of all durations / users num
//             },
//             hasCorrectAnswer: true,        // True if QUESTION in the Quiz has a correct answer as option
//             variantsNo: 0,                 // Total answers no

//             userAnswers: {                 // Cumulative counters for all users for table/ quesion

//                 total: 0,                  // Ideally = users num * questions num
//                 correct: 0,
//                 errors: 0,

//                 usersNo: 0,                // How many users took place in quiz at this table

//                 answerStats: {
//                     answerIndex: 0         // Per answer index counter
//                 }
//             }
//         }]
//     ),

//     questionsTotals: [{                    // Array of questions as in the Quiz
//         hasCorrectAnswer: true,            // True if QUESTION in the Quiz has a correct answer as option

//         grandTotals: Map( tableId: {      // Map of all tables affected

//             total: 0,                      //
//             correct: 0,
//             errors: 0,

//             answerStats: {
//                 [answerIndex]: 0           // Per answer index counter
//             }
//         }),

//         perTableTotals: Map( tableId: {    // Map of all tables affected

//             total: 0,                      //
//             correct: 0,
//             errors: 0,

//             answerStats: {
//                 [answerIndex]: 0           // Per answer index counter
//             }
//         })
//     }]
//

// }

function getEmptyUserAnswers(): IQResultsUserAnswers {
    return {
        total: 0,
        correct: 0,
        errors: 0,
        correctPercent: 0,
        answerStats: {},
    };
}

export function questionHasCorrectAnswer(question: IQuizQuestion): boolean {
    if (question.questionType === QuizQuestionTypes.Range) return false;

    const hasCorrectChoice =
        (question.questionType === QuizQuestionTypes.SingleChoice ||
            question.questionType === QuizQuestionTypes.MultiChoice) &&
        question.choices.some((choice: IQChoiceVariant) => choice.isCorrect);
    const hasCorrectFreeAnswer =
        question.questionType === QuizQuestionTypes.FreeString &&
        question.correctFreeAnswers.some((str) => str.length > 0);
    return hasCorrectChoice || hasCorrectFreeAnswer;
}

export function answerIsCorrect(question: IQuizQuestion, answer: IQuizUserAnswer): boolean {
    if (isEmpty(answer?.answers)) return false;

    switch (question.questionType) {
        case QuizQuestionTypes.SingleChoice: {
            const correctChoiceId = question.choices.find((choice: IQChoiceVariant) => choice.isCorrect)?.id;
            return answer.answers[correctChoiceId ?? 0] === true;
        }
        case QuizQuestionTypes.MultiChoice: {
            const correctChoiceIds = question.choices
                .filter((choice: IQChoiceVariant) => choice.isCorrect)
                .map((choice) => choice.id);
            const answerIds = Object.keys(answer.answers);
            return (
                correctChoiceIds.every((choiceId) => answerIds.includes(choiceId)) &&
                answerIds.length === correctChoiceIds.length
            );
        }
        case QuizQuestionTypes.FreeString: {
            return question.correctFreeAnswers.reduce(
                (acc, currValue: string, index) =>
                    (acc && currValue === '') || answer.answers[`${index}`] === currValue,
                false
            );
        }
        case QuizQuestionTypes.Range:
            return true;
    }
    return false;
}

export function questionHasNoOneAnswerOption(question: IQuizQuestion): boolean {
    if (question.questionType === QuizQuestionTypes.MultiChoice) {
        // TODO переделать на работу с норм признаком наличия варианта ответа "ни один из перечисленных"
        // Пока проверяем, что валидным выделен только один последний вариант ответа

        return isTrue(question.options?.['LastChoiceIsNoOneAnswer']);
        // const firstCorrectChoiceIndex = question.choices.findIndex(
        //     (choice: IQChoiceVariant) => choice.isCorrect
        // );
        // return question.choices.length > 1 && firstCorrectChoiceIndex === question.choices.length - 1;
    }
    return false;
}

export function choiceIsNoOneAnswer(question: IQuizQuestion, choiceId: string): boolean {
    if (!questionHasNoOneAnswerOption(question)) return false;

    // TODO переделать на работу с норм признаком наличия варианта ответа "ни один из перечисленных"
    // Проверяем, данный id варианта ответа на то, что это последний вариант ответа
    const сhoiceIndex = question.choices.findIndex((choice: IQChoiceVariant) => choice.id === choiceId);

    return сhoiceIndex === question.choices.length - 1;
}

/**
 * Для данного вопроса и ответа возвращает карту ID вариантов ответа и их отметки.
 * @param question The question
 * @param answer The answer
 * @returns A map of choice IDs to their answer marks
 */
export function answerChoicesMarks(
    question: IQuizQuestion,
    answer: IQuizUserAnswer
): { choices: IQQuestionAnsweredChoices; correctPercent: number; hasNoOneAnswerOption: boolean } {
    const choicesMarks: IQQuestionAnsweredChoices = {};
    const result = {
        choices: choicesMarks,
        correctPercent: 0,
        hasNoOneAnswerOption: false,
    };
    if (isEmpty(answer?.answers)) return result;

    switch (question.questionType) {
        case QuizQuestionTypes.SingleChoice: {
            const correctChoiceId =
                question.choices.find((choice: IQChoiceVariant) => choice.isCorrect)?.id ?? 0;
            const givenAnswerChoiceId = Object.keys(answer.answers)[0];
            choicesMarks[correctChoiceId] =
                givenAnswerChoiceId === correctChoiceId
                    ? QuizAnswerChoiceMark.CorrectMark
                    : QuizAnswerChoiceMark.ErrorNoMark;
            break;
        }
        case QuizQuestionTypes.MultiChoice: {
            result.hasNoOneAnswerOption = questionHasNoOneAnswerOption(question);

            // Отберем идентификаторы заведомо правильных вариантов по условиям вопроса
            const correctChoiceIds = question.choices
                .filter((choice: IQChoiceVariant, choiceIndex: number) => choice.isCorrect)
                .map((choice) => choice.id);

            const hasNoOneAnswerOptionIsCorrect =
                result.hasNoOneAnswerOption &&
                correctChoiceIds[0] === question.choices[question.choices.length - 1].id;

            const answerChoiceIds = Object.keys(answer.answers);

            // Отметим правильно выбранные варианты на основании набора правильных вариантов
            correctChoiceIds.forEach((correctChoiceId) => {
                if (isTrue(answer.answers[correctChoiceId])) {
                    choicesMarks[correctChoiceId] = QuizAnswerChoiceMark.CorrectMark;
                }
            });

            // Для вопроса с правильным вариантом "ни один из перечисленных" отметим правильно НЕ выбранные варианты
            if (result.hasNoOneAnswerOption) {
                question.choices.forEach((choice: IQChoiceVariant, choiceIndex: number) => {
                    if (choiceIndex < question.choices.length - 1 && isNil(choicesMarks[choice.id])) {
                        if (!isTrue(answer.answers[choice.id])) {
                            choicesMarks[choice.id] = QuizAnswerChoiceMark.CorrectNoMark;
                        }
                    }
                });
            }

            // Отметим ошибочно выбранные варианты
            // это оставшиеся варианты ответа, еще никак не отмеченные (как правильные)
            answerChoiceIds.forEach((answerChoiceId) => {
                if (isNil(choicesMarks[answerChoiceId])) {
                    choicesMarks[answerChoiceId] = QuizAnswerChoiceMark.ErrorMark;
                }
            });

            const correctMarksCount = Object.values(choicesMarks).reduce(
                (acc, mark) =>
                    mark === QuizAnswerChoiceMark.CorrectMark || mark === QuizAnswerChoiceMark.CorrectNoMark
                        ? acc + 1
                        : acc,
                0
            );
            // console.log('hasNoOneAnswerOptionIsCorrect', hasNoOneAnswerOptionIsCorrect);
            // console.log('correctMarksCount', correctMarksCount);
            // console.log('choices number', question.choices.length);
            // console.log('answerChoiceIds.length', answerChoiceIds.length);
            // console.log(
            //     'choiceIsNoOneAnswer(question, answerChoiceIds[0])',
            //     choiceIsNoOneAnswer(question, answerChoiceIds[0])
            // );
            // console.log('answerChoiceIds[0]', answerChoiceIds[0]);
            // console.log('answer.answers', answer.answers);

            // Если вопрос с вариантом "ни один из перечисленных"
            if (result.hasNoOneAnswerOption) {
                // Если выбран вариант "ни один из перечисленных"
                if (answerChoiceIds.length === 1 && choiceIsNoOneAnswer(question, answerChoiceIds[0])) {
                    // Если вариант "ни один из перечисленных" правильный и он единственный выбор
                    if (hasNoOneAnswerOptionIsCorrect) {
                        // то это 100% правильный ответ
                        result.correctPercent = 1;
                    } else {
                        // иначе это 0% правильный ответ
                        result.correctPercent = 0;
                    }
                } else {
                    // иначе, т.е. если вариантов ответа было больше одного или вообще ни одного

                    // Если вариант "ни один из перечисленных" НЕ правильный
                    if (!hasNoOneAnswerOptionIsCorrect) {
                        // Если количество правильных ответов пользователя равно количеству правильных вариантов ответа в вопросе
                        // то так может быть только если они совпали и это 100% правильный ответ
                        if (correctMarksCount === question.choices.length) {
                            result.correctPercent = 1;
                        } else {
                            // иначе вычисляем процент правильных ответов от количества просто вариантов ответа
                            // ибо все, что НЕ ПРАВИЛЬНЫЕ - все неправильные, исключая "ни один из перечисленных"
                            result.correctPercent = correctMarksCount / (question.choices.length - 1);
                        }
                    } else {
                        // Если вариант "ни один из перечисленных" правильный
                        // и у нас вариантов ответа больше одного или вообще ни одного
                        result.correctPercent = correctMarksCount / (question.choices.length - 1);
                    }
                }
            } else {
                // Если вопрос НЕ с вариантом "ни один из перечисленных"
                result.correctPercent =
                    question.choices.length === 0 ? 0 : correctMarksCount / question.choices.length;
            }

            break;
        }
        case QuizQuestionTypes.FreeString: {
            question.correctFreeAnswers.forEach((freeAnswer, index) => {
                choicesMarks[index] =
                    freeAnswer === answer.answers[`${index}`]
                        ? QuizAnswerChoiceMark.CorrectMark
                        : QuizAnswerChoiceMark.ErrorMark;
            });
            break;
        }
        case QuizQuestionTypes.Range:
            break;
    }
    return result;
}
/**
 * Описание пользователя, участвующего в опросе
 */
export type TQuizUser = {
    userId: string;
    activity: IQuizRunUserActivity;
    location: IUserLocation;
};

/**
 * Описание группы и перечень участников, использованной в расчете статистики.
 */
export type TQuizTable = {
    tableId: string;
    options: IOptions;
    userList: string[];
};

/**
 * Рассчитываем статистику по вопросам для каждого конкретного стола/ группы
 * @param tableId
 * @param quizRun
 * @param quizUsers
 * @param quizTotals
 * @returns Array<IQResultsQuestionTotals>
 */
export function calcQuestionsForTableTotals(
    tableId: string,
    quizRun: IQuizRun,
    quizUsers: TQuizUser[],
    quizTotals: IQResultsQuizTotals
): Array<IQResultsQuestionTotals> {
    return quizRun.quiz.questions.map((question, qIndex): IQResultsQuestionTotals => {
        let qDuration = 0;

        const hasCorrectAnswer = questionHasCorrectAnswer(question);

        const userAnswers = getEmptyUserAnswers();

        // quizUsers.filter((user) => tableId === user.location.tableId);
        const tableUsers = quizTotals.tables[tableId].userList.map((userId) =>
            quizUsers.find((user) => user.userId === userId)
        );

        const tableUsersNo = tableUsers.length;

        let tableCorrectPercent = 0;

        tableUsers.forEach((user) => {
            if (isNil(user)) {
                return;
            }
            const answer = user.activity?.answers?.[qIndex];
            const hasUserAnswer = !isEmpty(answer?.answers);

            if (hasUserAnswer) {
                userAnswers.total += 1;

                const userChoicesMarks = answerChoicesMarks(question, answer);

                quizTotals.users[user.userId].choices[String(question._id)] = userChoicesMarks.choices;
                quizTotals.users[user.userId].stats[String(question._id)] = {
                    correctPercent: userChoicesMarks.correctPercent,
                };

                tableCorrectPercent += userChoicesMarks.correctPercent;
                // инкрементируем для каждого данного варианта ответа общее количество так ответивших
                Object.keys(answer?.answers).forEach((answerId) => {
                    userAnswers.answerStats[answerId] = (userAnswers.answerStats[answerId] ?? 0) + 1;
                });
            }

            qDuration += Number(answer?.stats?.timeSpent ?? 0);
        });

        quizTotals.questionsFullyAnswered += userAnswers.total === tableUsersNo ? 1 : 0;
        quizTotals.questionsFullyCorrectlyAnswered += userAnswers.correct === tableUsersNo ? 1 : 0;

        const qMidTime = tableUsersNo === 0 ? 0 : qDuration / tableUsersNo;

        quizTotals.usersMaxMidTime = Math.max(qMidTime, quizTotals.usersMaxMidTime);

        quizTotals.usersMaxErrorsForQuestion = Math.max(
            quizTotals.usersMaxErrorsForQuestion,
            userAnswers.errors
        );

        return {
            hasCorrectAnswer,
            variantsNo: question.choices.length,
            duration: {
                midTime: qMidTime,
            },
            userAnswers,
            correctPercent: tableUsersNo === 0 ? 0 : tableCorrectPercent / tableUsersNo,
        };
    });
}

const calcTablePoints = (
    quiz: IQuiz,
    tables: IQResultsTables,
    perQuestionTotals: IQResultsAllQuestionTotals[]
): IQResultsPerTablePoints => {
    const result: IQResultsPerTablePoints = {};

    Object.keys(tables).forEach((tableId) => {
        result[tableId] = {
            correct: 0,
            incorrect: 0,
            total: 0,
            correctPercent: 0,
            perQuestion: [],
        };
    });

    let correctHit = 0;
    let incorrectHit = 0;

    perQuestionTotals.forEach((qTotals, qIndex) => {
        Object.keys(qTotals.perTableTotals).forEach((tableId: string) => {
            const question = quiz.questions[qIndex];
            const hasCorrectAnswer = questionHasCorrectAnswer(question);
            question.choices.forEach((choice: IQChoiceVariant) => {
                const choiceNum = qTotals.perTableTotals?.[tableId]?.answerStats?.[choice.id] ?? 0;
                if (choice.isCorrect) {
                    correctHit += choiceNum;
                } else {
                    if (hasCorrectAnswer) incorrectHit += choiceNum;
                }
            });
            result[tableId].correct += correctHit;
            result[tableId].incorrect += incorrectHit;
            result[tableId].total += correctHit - incorrectHit;

            result[tableId].correctPercent += qTotals.perTableTotals?.[tableId]?.correctPercent ?? 0;

            result[tableId].perQuestion[qIndex] = {
                correct: correctHit,
                incorrect: incorrectHit,
                total: correctHit - incorrectHit,
                correctPercent: qTotals.perTableTotals?.[tableId]?.correctPercent ?? 0,
            };

            correctHit = 0;
            incorrectHit = 0;
        });
    });

    Object.keys(tables).forEach((tableId: string) => {
        result[tableId].correctPercent = result[tableId].correctPercent / perQuestionTotals.length;
    });
    return result;
};

/**
 * Функция расчета всей статистики про результатам проведенного опроса
 * @param quiz - опрос
 * @param quizRun - результаты проведения опроса. Для опроса уже должна быть присвоена рассадка по столам (quizRun.grouping)
 * @returns полную статистику IQResultsFullResults
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function calcQuizStats(quiz: IQuiz, quizRun: IQuizRun): IQResultsFullResults | null {
    if (isEmpty(quiz) || isEmpty(quizRun)) {
        return emptyQuizRunResults;
    }

    // Собираем информацию по пользователям, которые будут учтены в статистике.
    // ======================
    // Cчитаем всех, кто нажал "начать". И не важно, был ли он на старте и был ли он на окончании опроса. Нажал - посчитан. Не нажимал - не посчитан.
    // Считаем начавших и завершивших (ИЛИ доживших до конца)
    // ======================

    // По группам:
    // - учитываем разбиение по группам если были созданы группы и в них был хоть один пользователь, начавший отвечать на опрос.
    //   Основную группу в этом случае не учитываем, были ти там начавшие опрос или не были.
    // - иначе вся статистика будет посчитана против одной основной группы.
    //   т.е. не учитываем разбиение по группам если групп не было или в группах не было ни одного пользователя, начавшего опрос.

    // Последовательность действий:
    // - Смотрим на группы, есть ли группы.
    //   Если группы есть, то проверяем тезис "в группах не было ни одного пользователя, начавшего опрос".
    //   Пробегаем по всем пользователям с активностью и смотрим их location, найдется ли хоть один с группой, отличной от основной.
    // - Собираем всех пользователей, которые имеют активность в опросе и статус QuizRunUserStatus.FINISHED. Они будут включены в статистику.
    // - Смотрим пользователей из снапшота конца опроса и добавляем тех, у кого по активности QuizRunUserStatus.INPROGRESS или QuizRunUserStatus.INREVIEW.

    // Все пользователи для расчетов
    let quizUsers: TQuizUser[] = [];
    // Все группы для расчетов и для помещения в итоговые результаты
    const tables: IQResultsTables = {};
    const users: IQResultsUsers = {};

    Object.keys(quizRun.users ?? {}).forEach((userId) => {
        quizUsers.push({
            userId,
            activity: quizRun.users?.[userId]?.activity as IQuizRunUserActivity, // надо присваивать именно так, чтобы срабатывали геттеры
            location: quizRun.grouping?.users?.[userId]?.location as IUserLocation,
        });
    });

    // Если у нас есть группы помимо основной. Иначе - все были в основной.
    if (Object.keys(quizRun.grouping?.tables ?? {}).length > 1) {
        // то проверяем тезис "в группах не было ни одного пользователя, начавшего опрос".
        if (quizUsers.some((user) => user.location.tableId !== hostTableNumber)) {
            // убираем из списка пользователей, которые начали опрос в основной группе
            quizUsers = quizUsers.filter((user) => user.location.tableId !== hostTableNumber);
        }
    }

    // Собираем информацию об имеющихся группах, где были активные пользователи
    quizUsers.forEach((user, userIndex) => {
        const tableId = user.location.tableId;
        if (isNil(tables[tableId])) {
            tables[tableId] = {
                title: quizRun.grouping?.tables?.[tableId]?.options?.title ?? tableId,
                userList: [],
            };
        }
        tables[tableId]?.userList.push(user.userId);

        users[user.userId] = {
            displayName: quizRun.grouping?.users?.[user.userId]?.displayName ?? `Attender ${userIndex}`,
            location: user.location,
            quizStatus: user.activity?.status,
            choices: {},
            stats: {},
            // quiz.questions.reduce((acc, curr) => {
            //     acc[String(curr._id)] = {};
            //     return acc;
            // }, {} as IQAnswersChoiceMarks),
        };
    });

    const quizTotals: IQResultsQuizTotals = {
        startTime: quizRun.stats?.startTime ?? 0,
        finishTime: quizRun.stats?.finishTime ?? 0,

        usersStarted: quizUsers.length,
        usersFinished: quizUsers.filter((user) => user.activity?.status == QuizRunUserStatus.FINISHED).length,
        usersMidTime:
            quizUsers.reduce(
                (accumulator, user) =>
                    accumulator +
                    calcDuration(user.activity?.stats?.startTime, user.activity?.stats?.finishTime),
                0
            ) / quizUsers.length,
        usersMaxMidTime: 0,

        usersMaxErrorsForQuestion: 0,

        questionsNo: quiz.questions.length,
        questionsWithCorrectAnswerNo: quiz.questions.filter((question) => questionHasCorrectAnswer(question))
            .length,

        questionsFullyAnswered: 0,
        questionsFullyCorrectlyAnswered: 0,

        tables,
        users,
    };

    quizTotals.duration = calcDuration(quizTotals.startTime, quizTotals.finishTime);

    // const tables = uniq(
    //     users
    //         .map(user => {
    //             let found = findItemsTable(user.userId);
    //             return (found.tableId ?? user.location.tableId)
    //         })
    //         .filter(tableId => !isNil(tableId)));

    const quizPerTableTotals: IQResultsPerTableTotals = {};
    Object.keys(tables).forEach((tableId) => {
        quizPerTableTotals[tableId] = calcQuestionsForTableTotals(tableId, quizRun, quizUsers, quizTotals);
    });

    function summator(objValue: never, srcValue: unknown) {
        if (isNumber(objValue) || isNumber(srcValue)) {
            return (objValue ?? 0) + (srcValue ?? 0);
        }
    }

    const quizPerQuestionTotals = quiz.questions.map((question, questionIndex) => {
        let questionTotals = getEmptyUserAnswers();
        const questionPerTableTotals: IQResultsPerQuestionPerTableTotals = {};

        Object.keys(tables).forEach((tableId) => {
            questionTotals = mergeWith(
                questionTotals,
                quizPerTableTotals[tableId]?.[questionIndex]?.userAnswers,
                summator
            );
            questionPerTableTotals[tableId] = mergeWith(
                questionPerTableTotals[tableId],
                quizPerTableTotals[tableId]?.[questionIndex]?.userAnswers,
                summator
            );
            questionPerTableTotals[tableId].correctPercent =
                quizPerTableTotals[tableId]?.[questionIndex]?.correctPercent ?? 0;
        });

        return {
            hasCorrectAnswer: questionHasCorrectAnswer(question),
            variantsNo: question.choices.length,
            perTableTotals: questionPerTableTotals,
            grandTotals: questionTotals,
        } as IQResultsAllQuestionTotals;
    });

    const quizPerTablePoints = calcTablePoints(quiz, tables, quizPerQuestionTotals);

    logger.trace('Quiz Totals: ', {
        quizTotals,
        quizPerTableTotals,
        quizPerQuestionTotals,
        quizPerTablePoints,
    });
    return {
        quizTotals,
        perTableTotals: quizPerTableTotals,
        perQuestionTotals: quizPerQuestionTotals,
        perTablePoints: quizPerTablePoints,
    };
}
