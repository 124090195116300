import { Logger } from '@tellsla/common';
import { isNil } from 'lodash-es';
import { Socket } from 'socket.io-client';

const logger = Logger('socket')

interface IRequestFunction {
    (type: string, requestData: any, millis?: number) : Promise<any>
}


export interface ISrvSocket extends Socket {
    request: IRequestFunction
}


let socket: ISrvSocket | null = null;

export function getSocket(): ISrvSocket | null { return socket; }

export function setSocket(newSocket: ISrvSocket | null) {
    if (!isNil(socket) && socket?.connected) {
        socket?.close();
    }
    socket = newSocket;
}

export function closeSocket() {
    logger.debug('closing socket. isNil(socket) : %s', isNil(socket))
    if (!isNil(socket)) {
        socket.removeAllListeners();
        socket.close();
        setSocket(null);
    }
}
