import { merge } from 'lodash-es';
import { defaultTableData, TABLE_OPTIONS } from '../table/constants';

export const ROOM_ADD_TABLE = 'ROOM_ADD_TABLE';
export const ROOM_RESET_TABLES = 'ROOM_RESET_TABLES';
export const ROOM_DEL_TABLE = 'ROOM_DEL_TABLE';
export const ROOM_REORDER_TABLE = 'ROOM_REORDER_TABLE';
export const ROOM_JOIN_TABLES = 'ROOM_JOIN_TABLES';
export const ROOM_ISOLATE_TABLES = 'ROOM_ISOLATE_TABLES';
export const ROOM_ADD_ITEM = 'ROOM_ADD_ITEM';
export const ROOM_DEL_ITEM = 'ROOM_DEL_ITEM';
export const ROOM_ADD_USER = 'ROOM_ADD_USER';
export const hostTableNumber = '0';
export const firstTableNumber = '1';

export const ROOM_OPTIONS = {
    canDeleteFlag: 'canDelete',
    canReorderFlag: 'canReorder',
    canAddTableFlag: 'canAddTable',
    title: 'title',
    color: 'color',
};

export const EXPO_VIEW_MODES = {
    grid: 'GRID',
    enlagedItem: 'ENLARGED_ITEM',
};

export const EXPO_OPTIONS = {
    enlargedItemId: 'enlargedItemId',
    enlargedItemType: 'enlargedItemType',
    viewMode: 'viewMode',
};

export function defaultRoomData(customData) {
    return merge(
        {
            // id: nanoid(4),
            expo: {
                itemList: [],
                items: {},
                pinnedItems: {},
                options: {},
            },

            // userList: [],
            // users: {},

            tableList: [hostTableNumber],
            tables: {
                // Create at least one table
                [hostTableNumber]: defaultTableData({
                    id: hostTableNumber,
                    options: {
                        [TABLE_OPTIONS.title]: 'Main',
                        [TABLE_OPTIONS.canDeleteFlag]: false,
                        [TABLE_OPTIONS.canReorderFlag]: false,
                    },
                }),
            },

            options: {
                [ROOM_OPTIONS.title]: '',
                [ROOM_OPTIONS.color]: null,
                [ROOM_OPTIONS.canDeleteFlag]: true,
                [ROOM_OPTIONS.canReorderFlag]: true,
            },
        },
        customData
    );
}

export const CLUB_ROOM = defaultRoomData({
    id: 'C',
    options: {
        [ROOM_OPTIONS.title]: 'Club',
        [ROOM_OPTIONS.canDeleteFlag]: false,
        [ROOM_OPTIONS.canReorderFlag]: false,
        [ROOM_OPTIONS.canAddTableFlag]: true,
    },
});

export const LOBBY_ROOM = defaultRoomData({
    id: 'L',
    options: {
        [ROOM_OPTIONS.title]: 'Lobby',
        [ROOM_OPTIONS.canDeleteFlag]: false,
        [ROOM_OPTIONS.canReorderFlag]: true,
        [ROOM_OPTIONS.canAddTableFlag]: false,
    },
    tables: {
        [hostTableNumber]: {
            options: {
                [TABLE_OPTIONS.title]: '',
            },
        },
    },
});
