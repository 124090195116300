import { Stack, styled } from "@mui/material";
import { IQResultsFullResults } from "@tellsla/common";
import { IGroupingSnapshot, IQuizQuestion, IQuizUserAnswer } from "@tellsla/serverTypes";
import React from "react";
import { QuizReportQuestionGroupTotalPoints } from "./QuizReportQuestionGroupTotalPoints";
import { QuizReportQuestionTbl } from "./QuizReportQuestionTbl";

export const Root = styled("div")(({ theme }) => ({
    paddingTop: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    alignSelf: "start",
    width: "100%",
}));

export interface QuizResultsTotalsPerQuestionProps {
    questions: Array<IQuizQuestion>;
    selfAnswers: Array<IQuizUserAnswer>;
    results: IQResultsFullResults;
    selfUserId: string;
    selfUserRole: string;
}

export const QuizResultsTotalsPerQuestion: React.FC<
    QuizResultsTotalsPerQuestionProps
> = ({ questions, selfAnswers, results, selfUserId, selfUserRole }) => {
    return (
        <Root>
            <Stack direction="column" sx={{ maxWidth: "100%" }}>
                {(questions ?? []).map((question, qIndex) => (
                    <QuizReportQuestionTbl
                        key={`qrqt-${qIndex}`}
                        results={results}
                        questionIndex={qIndex}
                        question={questions[qIndex]}
                        selfAnswer={selfAnswers[qIndex]}
                        selfUserId={selfUserId}
                        selfUserRole={selfUserRole}
                    />
                ))}
                <QuizReportQuestionGroupTotalPoints results={results} />
            </Stack>
        </Root>
    );
};
