/* eslint-disable no-console */
import { merge } from 'lodash-es';

export const EventRunRoles = {
    ATTENDER : 'ATTENDER',
    COMOD : 'COMOD',
    MODER : 'MODER',
    COORG : 'COORG',
    ORG : 'ORG',
    ADMIN : 'ADMIN',
    AUDITOR : 'AUDITOR',
    UBERADMIN : 'UBERADMIN',
}
export type EventRunRoles = typeof EventRunRoles[keyof typeof EventRunRoles];

type IBoolFlags = {
    [key: string]: boolean;
};

type IAccessRights = {
    eventRun: {
        sendEmo: boolean;
        viewEmo: boolean;
    };
    chat: {
        view: boolean;
        change: boolean;
    };
    playbook: {
        change: boolean;
        view: boolean;
    };
    quiz: {
        change: boolean;
        viewQuizTelemetry: boolean;
    };
    room: {
        viewAll: boolean;
        enter: boolean;
        moveUser: boolean;
        manageTables: boolean;
    };
    exposition: {
        shareScreen: boolean;
    };
    userTelemetry: {
        change: boolean;
        view: boolean;
    };

    [key: string]: IBoolFlags;
}

type EventRunRoleRights = {
    isAttender: boolean;
    isModer: boolean;
    isOrg: boolean;
    isAdmin: boolean;
    isVisible: boolean;
    accessRights: IAccessRights;

    [key: string]: IAccessRights | boolean;
}

export const eventRunRoles = new Map<EventRunRoles | string, EventRunRoleRights>();

eventRunRoles.set(EventRunRoles.ATTENDER, {
    isAttender: true,
    isModer: false,
    isOrg: false,
    isAdmin: false,
    isVisible: true,
    accessRights: {
        eventRun: {
            sendEmo: true,
            viewEmo: true,
        },
        chat: {
            view: true,
            change: true,
        },
        exposition: {
            shareScreen: true,
        },
        userTelemetry: {
            change: true,
            view: false,
        },
        playbook: {
            change: false,
            view: false,
        },
        quiz: {
            change: false,
            viewQuizTelemetry: false,
        },
        room: {
            viewAll: false,
            enter: false,
            moveUser: false,
            manageTables: false,
        },
    },
});
eventRunRoles.set(
    EventRunRoles.COMOD,
    merge({}, eventRunRoles.get(EventRunRoles.ATTENDER), {
        isModer: true,
        accessRights: {
            eventRun: {
                switchUserMedia: true,
                switchUserDevice: true,
            },
            table: {
                change: true,
                reorderUsers: true,
            },
            exposition: {
                addSelfVideo: true,
                addUserVideo: true,
            },
        },
    })
);

eventRunRoles.set(
    EventRunRoles.MODER,
    merge({}, eventRunRoles.get(EventRunRoles.COMOD), {
        isModer: true,
        accessRights: {
            eventRun: {
                grantComod: true,
            },
        },
    })
);

eventRunRoles.set(
    EventRunRoles.COORG,
    merge({}, eventRunRoles.get(EventRunRoles.MODER), {
        isAttender: false,
        isOrg: true,
        accessRights: {
            eventRun: {
                change: true,
                tableIsolation: true,
                grantModer: true,
                startNew: true,
            },
            userTelemetry: {
                view: true,
            },
            playbook: {
                view: true,
                change: true,
            },
            quiz: {
                change: true,
                viewQuizTelemetry: true,
            },
            room: {
                viewAll: true,
                enter: true,
                moveUser: true,
                manageTables: true,
            },
            table: {
                enter: true,
                crossMoveUsers: true,
                listen: true,
            },
            exposition: {
                addMedia: true,
                managePresenter: true,
                changeViewMode: true,
            },
        },
    })
);

eventRunRoles.set(
    EventRunRoles.ORG,
    merge({}, eventRunRoles.get(EventRunRoles.COORG), {
        accessRights: {
            eventRun: {
                grantCoorg: true,
            },
        },
    })
);

eventRunRoles.set(
    EventRunRoles.ADMIN,
    merge({}, eventRunRoles.get(EventRunRoles.ORG), {
        isAdmin: true,
    })
);

eventRunRoles.set(
    EventRunRoles.AUDITOR,
    merge({}, eventRunRoles.get(EventRunRoles.ATTENDER), {
        isVisible: false,
        accessRights: {
            eventRun: {
                sendEmo: false,
            },
            userTelemetry: {
                view: true,
                change: false,
            },
            playbook: {
                view: true,
            },
            quiz: {
                viewQuizTelemetry: true,
            },
            room: {
                viewAll: true,
                enter: true,
            },
            table: {
                enter: true,
                listen: true,
            },
            exposition: {
                shareScreen: false,
            },
        },
    })
);

export const isAttenderRole = (role: string): boolean => eventRunRoles.get(role)?.isAttender === true;
export const isInvisibleRole = (role: string): boolean => eventRunRoles.get(role)?.isVisible === false;
export const isVisibleRole = (role: string): boolean => !isInvisibleRole(role);

export const isUberAdminRole = (role: string): boolean => role === 'UBERADMIN';
export const isOrgRole = (role: string): boolean => eventRunRoles.get(role)?.isOrg === true;
export const isModeratorRole = (role: string): boolean => eventRunRoles.get(role)?.isModer === true;

export const peerRoleCan = (role: string = '', region = '', right = ''): boolean =>
    (eventRunRoles.get(role.toUpperCase()) as EventRunRoleRights)?.accessRights[region]?.[right] === true;
export const peerRoleIs = (role = '', qualifier = ''): boolean =>
    (eventRunRoles.get(role.toUpperCase()) as EventRunRoleRights)?.[qualifier] === true;

export const canChangeLayout = (role: string): boolean => peerRoleIs(role, 'isOrg');
export const canViewFullLayout = (role: string): boolean => peerRoleIs(role, 'isOrg');

export const canViewEventTelemetry = (role: string): boolean => peerRoleIs(role, 'isOrg');
export const canViewUserTelemetry = (role: string): boolean => peerRoleCan(role, 'userTelemetry', 'view');
export const canViewQuizTelemetry = (role: string): boolean => peerRoleCan(role, 'quiz', 'viewQuizTelemetry');

export const canViewResource = (role: string, resourceName: string): boolean =>
    peerRoleCan(role, resourceName, 'view');
export const canChangeResource = (role: string, resourceName: string): boolean =>
    peerRoleCan(role, resourceName, 'change');

export const canStartEventRun = (role: string): boolean => peerRoleCan(role, 'eventRun', 'startNew');

export default {
    EventRunRoles,
    isAttenderRole,
    isInvisibleRole,
    isVisibleRole,
    isOrgRole,
    isModeratorRole,
    peerRoleCan,
    peerRoleIs,
    canChangeLayout,
    canViewFullLayout,
    canViewEventTelemetry,
    canViewUserTelemetry,
    canViewQuizTelemetry,
    canViewResource,
    canChangeResource,
    canStartEventRun,
};
