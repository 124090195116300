import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import { config } from '@tellsla/config';
import { isFunction, isString } from 'lodash';
import React, { MouseEventHandler } from 'react';
import { SmartButtonGroup } from './SmartButtonGroup';
import { strongNavigate } from './strongNavigate';
import { Close } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';

interface IButtonDescription {
    id?: string;
    text?: string;
    function?: MouseEventHandler;
    icon?: React.ReactElement;
}

export interface IUniversalModalAlertData {
    title?: string;
    content?: React.ReactElement | string;
    buttons?: Array<IButtonDescription>;
}

interface IUniversalModalAlertProps extends IUniversalModalAlertData {
    id?: string;
    isOpen: boolean;
    disableBackdropClick?: boolean;
    handleModalClose: (event?: any, reason?: any) => void;
}

export function UniversalModalAlert({
    id,
    title = '',
    content = '',
    buttons = [{}],
    isOpen = false,
    disableBackdropClick = true,
    handleModalClose = () => {},
}: IUniversalModalAlertProps) {
    const { t } = useTranslation();
    return (
        <Dialog
            id={id ?? 'universal-modal-alert'}
            open={isOpen}
            onClose={(event, reason) => {
                if (disableBackdropClick) {
                    if (reason !== 'backdropClick') {
                        handleModalClose(event, reason);
                    }
                } else if (isFunction(handleModalClose)) {
                    handleModalClose();
                } else {
                    strongNavigate(config.frontendHost.fallbackPath);
                }
            }}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            disableEscapeKeyDown={disableBackdropClick}>
            <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
            <DialogContent>
                {isString(content) ? (
                    <DialogContentText id="alert-dialog-description">{content}</DialogContentText>
                ) : (
                    content
                )}
            </DialogContent>
            {buttons?.length > 0 && (
                <DialogActions>
                    {buttons?.length > 1 ? (
                        <SmartButtonGroup>
                            {buttons.map((button: IButtonDescription, index) => (
                                <Button
                                    id={button.id ?? button.text}
                                    key={button.text}
                                    onClick={button.function}
                                    startIcon={button.icon}
                                    variant={index === buttons.length - 1 ? 'contained' : undefined}>
                                    {button.text}
                                </Button>
                            ))}
                        </SmartButtonGroup>
                    ) : (
                        <Button
                            id={buttons[0].id ?? `alert-dialog-button-${buttons[0].text}`}
                            key={`alert-dialog-button-${buttons[0].text}`}
                            startIcon={buttons[0].icon ?? <Close />}
                            onClick={buttons[0].function ?? handleModalClose}
                            variant="contained">
                            {buttons[0].text ?? t('Close')}
                        </Button>
                    )}
                </DialogActions>
            )}
        </Dialog>
    );
}

export type TUniversalModalAlertParams = {
    id?: string;
    title?: string;
    content?: React.ReactElement | string;
    buttons?: Array<IButtonDescription>;
    disableBackdropClick?: boolean;
    onClose?: (event?: any, reason?: any) => void;
};

export interface IUniversalModalAlertContext {
    openUniversalModal: (data: TUniversalModalAlertParams) => void;
}

export const UniversalModalAlertContext = React.createContext<IUniversalModalAlertContext>({
    openUniversalModal: (data) => {},
});

export const useUniversalModalAlert = () => React.useContext(UniversalModalAlertContext);

export const UniversalModalAlertProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
    const [modalData, setModalData] = React.useState<TUniversalModalAlertParams>();

    const handleModalClose = (event, reason) => {
        setModalData(undefined);
        if (isFunction(modalData?.onClose)) {
            modalData.onClose(event, reason);
        }
    };

    return (
        <UniversalModalAlertContext.Provider value={{ openUniversalModal: setModalData }}>
            {children}
            {
                <UniversalModalAlert
                    {...modalData}
                    handleModalClose={handleModalClose}
                    isOpen={Boolean(modalData)}
                />
            }
        </UniversalModalAlertContext.Provider>
    );
};
