import { isArray, isNil } from 'lodash-es';

export interface IFrontHostConfig {
    protocol: string;
    host: string;
    port: number;
    timeout: number;
    fallbackPath?: string;
}

export interface IBackHostConfig extends IFrontHostConfig {
    restApi: {
        path: string;
        headers: { [key: string]: string };
    };
    wsApi: {
        path: string;
    };
}

export function baseApiUrl(hostConf?: IFrontHostConfig | null): string {
    if (!hostConf?.protocol || !hostConf?.host || !hostConf?.port) {
        if (window) return window.location?.origin ?? '';
        return '';
    }
    return `${hostConf.protocol}://${hostConf.host}:${hostConf.port}`;
}

export function getFileUrl(recordId: string, clientConfig: IBackHostConfig | null = null): string {
    if (isNil(recordId)) return '';
    return `${baseApiUrl(clientConfig)}${clientConfig?.restApi?.path ?? '/api'}/files/${recordId}`;
}

export function getUserAvatarUrl(recordId: string, clientConfig?: IBackHostConfig): string {
    if (isNil(recordId)) return '';
    return `${baseApiUrl(clientConfig)}${clientConfig?.restApi?.path ?? '/api'}/users/${recordId}/avatar`;
}

export function getAvatarFileUrl(recordId: string, clientConfig?: IBackHostConfig): string {
    if (isNil(recordId)) return '';
    return `${baseApiUrl(clientConfig)}${clientConfig?.restApi?.path ?? '/api'}/avatar/${recordId}/file`;
}

/**
 * Получить URL личного кабинета
 * @param eventId Идентификатор программы
 * @param includeHost Флаг необходимости включить в URL также и имя хоста
 * @returns строку с URL
 */
export function getAdminUrl(eventId: string, includeHost: boolean = false): string {
    // TODO: решить, нужно тут включать адрес текущего хоста или хост из конфига
    return `${
        includeHost === true && typeof window !== 'undefined' ? window.location.origin : ''
    }/admin/events/${eventId}`;
}

/**
 * Получить URL личного кабинета
 * @param eventId Идентификатор программы
 * @param includeHost Флаг необходимости включить в URL также и имя хоста
 * @returns строку с URL
 */
export function getEventRunEditUrl(eventId: string, eventRunId: string, includeHost: boolean = false): string {
    // TODO: решить, нужно тут включать адрес текущего хоста или хост из конфига
    return `${
        includeHost === true && typeof window !== 'undefined' ? window.location.origin : ''
    }/admin/events/${eventId}/run/${eventRunId}`;
}

/**
 * Получить URL Сессии
 * @param eventId Идентификатор программы
 * @param includeHost Флаг необходимости включить в URL также и имя хоста
 * @returns строку с URL
 */
export function getEventRunUrl(eventId: string, includeHost: boolean = false): string {
    // TODO: решить, нужно тут включать адрес текущего хоста или хост из конфига
    return `${includeHost === true ? window.location.origin : ''}/e/${eventId}`;
}

/**
 * Получить URL для подключения к Сессии
 * @param eventId Идентификатор программы
 * @param includeHost Флаг необходимости включить в URL также и имя хоста
 * @returns строку с URL
 */
export function getEventRunJoinUrl(eventId: string, includeHost?: boolean): string {
    return `${getEventRunUrl(eventId, includeHost)}/join`;
}

export function getPreviewFileUrl(files: Array<any>): string | null {
    if (isNil(files) || !isArray(files)) {
        return null;
    }

    const fileItem = files?.find(
        (file) =>
            file.options.useFor?.toLowerCase() === 'preview' ||
            file.options.useFor?.toLowerCase() === 'screenshot'
    );
    if (isNil(fileItem) || isNil(fileItem?.fileId)) {
        return null;
    }

    return getFileUrl(fileItem.fileId);
}

export function getFullImageFileUrl(files: Array<any>) {
    const fileItem = files?.find(
        (file) =>
            file.options.useFor?.toLowerCase() === 'jpeg' ||
            file.options.useFor?.toLowerCase() === 'fullimage'
    );
    if (isNil(fileItem) || isNil(fileItem?.fileId)) {
        return null;
    }

    return getFileUrl(fileItem.fileId);
}
