import { useTheme, Box } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import Copyright from './Copyright';
import { IBackgroundColors } from '../Theme/theme';
import { SnackbarProvider } from 'notistack';
import { NotificationListener } from '../Notify';

export function LandingFrame(props: React.PropsWithChildren<any>) {
    const theme = useTheme();
    const bgColors = theme.palette.background as IBackgroundColors;

    return (
        <SnackbarProvider maxSnack={3} preventDuplicate>
            <Box
                id="LandingFrame"
                sx={{
                    position: 'fixed',
                    overflow: 'auto',
                    display: 'flex',
                    flexDirection: 'column',
                    width: '100%',
                    height: '100%',
                    minHeight: '100vh',
                    alignItems: 'center',
                    top: 0,
                    left: 0,
                    background: bgColors.landing,
                }}
            >
                <Box
                    sx={{
                        maxWidth: 'md',
                        width: '100%',
                        marginTop: theme.spacing(2),
                        position: 'relative',
                        minHeight: `calc(100vh - ${theme.spacing(2)})`,
                        display: 'flex',
                        flexDirection: 'column',
                    }}
                >
                    {props.children}

                    <Box
                        id="CopyrightBox"
                        sx={{
                            padding: theme.spacing(3),
                            width: '100%',
                            marginTop: 'auto',
                        }}
                    >
                        <Copyright />
                    </Box>
                </Box>
            </Box>
            <NotificationListener />
        </SnackbarProvider>
    );
}

LandingFrame.propTypes = {
    children: PropTypes.node,
};
