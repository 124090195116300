import React from 'react';

export interface TabsContextType {
    idPrefix: string | null;
    tabIndex: number;
}

export const TabsContext = React.createContext<TabsContextType>({
    idPrefix: null,
    tabIndex: 0,
});

export const TabIsHiddenContext = React.createContext<boolean>(true);

export function getTabsPanelId(context: TabsContextType, index: number) {
    return `${context?.idPrefix ?? 'default'}-panel-${index}`;
}
export function getTabsTabId(context: TabsContextType, index: number) {
    return `${context?.idPrefix ?? 'default'}-tab-${index}`;
}
