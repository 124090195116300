// import 'react-app-polyfill/ie9';
// import 'react-app-polyfill/ie11';
// import 'react-app-polyfill/stable';

import React from 'react';
import { createRoot } from 'react-dom/client';
import './i18n';
import App from './App';
import { addRootHTTPTransport, sessionSetRuntimeId } from '@tellsla/serverApi';
import { setRootLoggerSettings } from '@tellsla/common';
import { config } from '@tellsla/config';

sessionSetRuntimeId('Landing');
addRootHTTPTransport();

setRootLoggerSettings({
    minLevel: config.logs.minLevel,
    stylePrettyLogs: true,
});

const container = document.getElementById('root');
const root = createRoot(container); // createRoot(container!) if you use TypeScript
root.render(<App />);
