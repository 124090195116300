import { Check, DoneAll, KeyboardArrowDown } from '@mui/icons-material';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Typography,
} from '@mui/material';
import { Logger } from '@tellsla/common';
import React from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { useTranslation } from 'react-i18next';
import { Outlet } from 'react-router-dom';

const logger = Logger('ErrorFallback');

interface ErrorFallbackProps {
    error: Error;
    resetErrorBoundary: () => void;
}

export const ErrorFallback: React.FC<ErrorFallbackProps> = ({ error, resetErrorBoundary }) => {
    logger.fatal(`Client crash: ${error?.message}`, error);

    const { t } = useTranslation();

    const onClose = () => {
        window.location.reload();
        resetErrorBoundary();
    };

    return (
        <Dialog open onClose={onClose}>
            <DialogTitle>{t('Something went wrong')}</DialogTitle>
            <DialogContent>
                <DialogContentText>{t('Notification about this problem has been sent to the support team')}.</DialogContentText>
                <DialogContentText>{t('Application need to be reloaded')}.</DialogContentText>
                <Accordion sx={{ mt: 2 }}>
                    <AccordionSummary expandIcon={<KeyboardArrowDown />}>
                        <Typography>{t('Error message')}: {error.message}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography variant="body2">{error.stack}</Typography>
                    </AccordionDetails>
                </Accordion>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>{t('Reload')}</Button>
            </DialogActions>
        </Dialog>
    );
};

export const ErrorBoundaryLayout = () => (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
        <Outlet />
    </ErrorBoundary>
);