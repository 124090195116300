import { Box, Grid, Typography } from "@mui/material";
import { LandingBase } from "@tellsla/ui-run";
import { filesize } from "filesize";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { DiscountGrid } from "./DiscountGrid";
import { TariffArrayShow } from "./TariffArrayShow";
import { TariffCard } from "./TariffCard";
import { TariffCostField } from "./TariffCostField";
import { TariffField } from "./TariffField";
import { TariffLabel } from "./TariffLabel";
import { TariffPriceButton } from "./TariffPriceButton";
import StarBorderIcon from "@mui/icons-material/StarBorder";

export interface IDiscount {
    discount: number;
    monthNumber: number;
}

export interface ITariffParams {
    key: string;
    value: string;
}

interface ITariffData {
    name: string;
    description: string;
    cost: number;
    onlineUsers: number;
    onlineEvents: number;
    fileStorageCapacity: number;
    commonParams: Array<ITariffParams>;
    customParams: Array<ITariffParams>;
}

const discounts: Array<IDiscount> = [
    { monthNumber: 1, discount: 0 },
    { monthNumber: 3, discount: 5 },
    { monthNumber: 6, discount: 10 },
    { monthNumber: 12, discount: 15 },
];

export const TariffInfoBoard = () => {
    const [currentDiscount, setCurrentDiscount] = useState<IDiscount>({ discount: 0, monthNumber: 1 });
    const [objectTariffs, setObjectTariffs] = useState<Array<ITariffData>>([]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                // в функцию
                // const response = await fetch("../public/tariffs.json");
                const response = await fetch("/tariffs.json");
                const data = await response.json();

                setObjectTariffs(data);
            } catch (error) {
                console.error("Ошибка загрузки файла тарифов ", error);
            }
        };

        fetchData();
    }, []);

    const { t } = useTranslation();
    return (
        <Box>
            <LandingBase>
                <DiscountGrid discounts={discounts} setDiscount={setCurrentDiscount} currentDiscount={currentDiscount} />

                <Grid container justifyContent={"center"}>
                    {objectTariffs.map((tariff: ITariffData, key: number) => (
                        <TariffCard key={key}>
                            <Box sx={{ display: "flex", justifyContent: "center", margin: "4px", mb: "8px" }}>
                                <StarBorderIcon />
                                {/* стоит менять */}
                            </Box>

                            <TariffLabel variant="h5" color={"primary"}>
                                {tariff.name}
                            </TariffLabel>
                            <TariffField sx={{ textAlign: "center" }}>{t(tariff.description)}</TariffField>
                            <TariffCostField text={tariff.cost} />

                            <TariffField>
                                {t("up to")} {tariff.onlineUsers} {t("attenders")}
                            </TariffField>
                            <TariffField>
                                {t("up to")} {tariff.onlineEvents} {t("online events")}
                            </TariffField>
                            <TariffField>
                                {filesize(tariff.fileStorageCapacity)} {t("storage capacity")}
                            </TariffField>
                            <TariffArrayShow array={tariff.commonParams} />

                            <TariffPriceButton variant="contained">
                                {tariff.cost == 0
                                    ? "Trial"
                                    : `${t("Buy for")} ${(tariff.cost * currentDiscount.monthNumber * (100 - currentDiscount.discount)) / 100}`}
                            </TariffPriceButton>

                            {tariff.cost !== 0 && currentDiscount.discount !== 0 && (
                                <Typography variant="body2" sx={{ textAlign: "center" }}>
                                    {t("Instead of")} {tariff.cost * currentDiscount.monthNumber}
                                </Typography>
                            )}
                            <TariffArrayShow array={tariff.commonParams}></TariffArrayShow>
                        </TariffCard>
                    ))}
                </Grid>
            </LandingBase>
        </Box>
    );
};
