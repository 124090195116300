import { isNil } from 'lodash';
import { notifyError } from '../../components';
import { Logger } from '@tellsla/common';

const logger = Logger('media.attachSpeakers');

/**
 * Присоединяем элементу вывод звука на указанное устройство
 * @param element
 * @param deviceId
 * @returns
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any

export function attachSpeakers(element: any, deviceId?: string | null) {
    if (isNil(deviceId)) return;

    if (!isNil(element) && !isNil(element.sinkId)) {
        element
            .setSinkId(deviceId)
            .then(() => {
                logger.trace(`Success, audio output device attached: ${deviceId}`);
            })
            .catch((error) => {
                let errorMessage = error;
                if (error.name === 'SecurityError') {
                    errorMessage = `You need to use HTTPS for selecting audio output device: ${error}`;
                }
                logger.error(errorMessage, { error });
                notifyError(errorMessage);
            });
    } else {
        logger.info(`Browser does not support output device selection. (deviceId: ${deviceId})`);
    }
}
