import { isError, isNil } from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getEventRunPublicData } from '@tellsla/serverApi';
import { Logger } from '@tellsla/common';
import { notifyError } from '../Notify';
import EventRunCard from './EventRunCard';

const logger = Logger('EventRunInfo');

interface IEventRunInfoProps {
    eventId: string;
    sx: any;
}

interface IEventRunInfo {
    title: string;
    description: string;
    status: string;
    eventType: string;
}

export function EventRunInfo({ eventId, sx }: IEventRunInfoProps): JSX.Element {
    const [eventInfo, setEventInfo] = useState<IEventRunInfo | null>(null);

    const { t } = useTranslation();

    useEffect(() => {
        function noEventData(error?: Error | null) {
            return {
                title: isError(error) ? t('Cant get event info') : t('No such event'),
                description: isError(error) ? error.message : t('Nothing found on the server'),
                status: t('no data'),
                eventType: t('no data'),
            };
        }

        getEventRunPublicData(eventId)
            .then((result: Error | any) => {
                // Get Event name and some other details
                logger.info(`Got event info for event ${eventId}`, { result });

                if (isNil(eventInfo))
                    setEventInfo(isError(result) || isNil(result) ? noEventData(result) : result);
            })
            .catch((error) => {
                if (isNil(eventInfo)) setEventInfo(noEventData(error));
                notifyError(error);
            });
    }, [eventId, t]);

    return <EventRunCard eventInfo={eventInfo} sx={sx} />;
}
