import { ILogRecord } from '@tellsla/serverTypes';
import { merge } from 'lodash-es';
import { ILogObj, Logger as TsLogger } from 'tslog';

export type TLogger = TsLogger<ILogObj>;

const rootLogTransports: Array<any> = [];

const logTransport = (logObj: any) => {
    rootLogTransports.forEach((transport) => transport(logObj));
};

const rootNativeLogger = new TsLogger<ILogObj>({
    // hideLogPositionForProduction: true,
    stylePrettyLogs: false,
    minLevel: 2, // 0: silly, 1: trace, 2: debug, 3: info, 4: warn, 5: error, 6: fatal
    prettyLogTemplate: "{{hh}}:{{MM}}:{{ss}}:{{ms}}\t{{logLevelName}}\t{{name}}\t",
    // prettyLogStyles: {
    //     filePathWithLine: 'hidden',
    //     dateIsoStr: 'hidden',
    // }
    attachedTransports: [logTransport],
});

export function setRootLoggerSettings(settings: any) {
    // console.log('setRootLoggerSettings', merge({}, rootNativeLogger.settings, settings));
    rootNativeLogger.settings = merge({}, rootNativeLogger.settings, settings);
}

export function attachRootLoggerTransport(transport: any) {
    // rootNativeLogger.attachTransport(transport);
    rootLogTransports.push(transport);
}

export const rootLogger = Logger('@t', rootNativeLogger);

/**
 * @param moduleName имя пакета, под которым будут выводиться сообщения в лог
 * @param parentLogger родительский логгер. Если не задан, то будет использоваться rootLogger
 * @param mdc дополнительные данные для логирования
 * @returns новый логгер
 */
export function Logger(moduleName: string, parentLogger?: TsLogger<ILogObj>, mdc?: ILogRecord): TLogger {
    const name = moduleName;

    const logger: any = (parentLogger ?? rootLogger).getSubLogger({ name }, mdc);

    return logger;
}

export function subLogger(logger: TLogger, moduleName: string, mdc?: ILogRecord): TLogger {
    const name = moduleName;

    const subLogger = logger.getSubLogger({ name, minLevel: 2 }, mdc);

    return subLogger;
}

export default Logger;
