import React from 'react';

interface IVideoPlayerEventPublicatorContext {
    publishPlay: (timeMark: number) => void;
    publishPause: (timeMark: number) => void;
    publishSeek: (timeMark: number) => void;
    publishFinish: (duration: number) => void;
    publishMuted: (muted: boolean) => void;
    publishVolumeChange: (value: number) => void;
    publishTelemetry: (currentTime: number, duration: number) => void;
}
export const VideoPlayerEventPublicatorContext = React.createContext<IVideoPlayerEventPublicatorContext>({
    publishPlay: (timeMark: number) => {},
    publishPause: (timeMark: number) => {},
    publishSeek: (timeMark: number) => {},
    publishFinish: (duration: number) => {},
    publishMuted: (muted: boolean) => {},
    publishVolumeChange: (value: number) => {},
    publishTelemetry: (currentTime: number, duration: number) => {},
});
